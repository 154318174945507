import React from 'react';

import {
  DangerButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '../../framework-components/button';

class ExampleButtons extends React.Component {
  render() {
    return (
      <div className="mt-10 mb-10 space-y-6">
        <h3 className="text-2xl font-bold">Buttons</h3>
        <div className="flex flex-wrap">
          <PrimaryButton label="Confirm" className="m-2" />
          <PrimaryButton label="Loading" className="m-2" isLoading />
          <PrimaryButton label="Disabled" className="m-2" isDisabled />
        </div>

        <div className="flex flex-wrap">
          <SecondaryButton label="Cancel" className="mr-2" />
          <SecondaryButton label="Loading" className="mr-2" isLoading />
          <SecondaryButton label="Disabled" isDisabled />
        </div>

        <div className="flex flex-wrap">
          <TertiaryButton label="Cancel" className="mr-2" />
          <TertiaryButton label="Loading" className="mr-2" isLoading />
          <TertiaryButton label="Disabled" isDisabled />
        </div>

        <div className="flex flex-wrap">
          <DangerButton label="Delete" className="mr-2" />
          <DangerButton label="Loading" className="mr-2" isLoading />
          <DangerButton label="Disabled" isDisabled />
        </div>
      </div>
    );
  }
}
export default ExampleButtons;
