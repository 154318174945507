import React from 'react';
import { Link } from 'react-router-dom';
import * as i18next from 'i18next';

import LoginForm from './login-form';
import { EMAIL_ADDRESS } from '../../constants';

const Login: React.FC = () => (
  <div className="space-y-6">
    <LoginForm />
    <p className="text-center">
      <Link className="text-blue-600 hover:text-blue-900" to="/forgot-password">
        {i18next.t('Forgot Password?')}
      </Link>
    </p>
    <p className="text-center text-gray-500">
      {i18next.t('Not a member yet?')}
      <Link className="text-blue-600 hover:text-blue-900" to="/register">
        {i18next.t('Sign up')}
      </Link>
    </p>
    <p>
      <a
        href={`mailto:${EMAIL_ADDRESS}`}
        className="text-blue-600 visited:text-blue-600 hover:text-blue-900"
      >
        {i18next.t('Contact us')}
      </a>
    </p>
  </div>
);

export default Login;
