/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import { Formik, Form, FormikHelpers, FieldArray, getIn } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as i18next from 'i18next';

import { useSubmitFormToAPI } from '../../api/forms';
import { SurveyContext } from '../../context-providers/survey-context';
import { apiRequest, useAPI } from '../../api/api';
import NavigationProgress from '../navigation-progress';
import TextArea from '../../framework-components/textarea';
import Radio from '../../framework-components/select/radio';
import { Option } from '../../framework-components/select/types';
import Loading from '../../framework-components/loading';
import { AddButton } from '../../framework-components/button';
import Checkbox from '../../framework-components/select/checkbox';

interface Resident {
  resident_data: { resident_id: string; age: string };
  weekday_hours_data: {
    morning: boolean;
    afternoon: boolean;
    evening: boolean;
    night: boolean;
    flexible_working: boolean;
  };
  weekend_hours_data: {
    morning: boolean;
    afternoon: boolean;
    evening: boolean;
    night: boolean;
    flexible_working: boolean;
  };
}

interface Values {
  additional_notes: string;
  residents: Array<Resident>;
}

const age_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Under 16 years'),
    value: 'UNDER_SIXTEEN',
  },
  {
    label: i18next.t('16 - 25 years'),
    value: 'SIXTEEN_TO_TWENTY_FIVE',
  },
  {
    label: i18next.t('26 - 49 years'),
    value: 'TWNETY_SIX_TO_FORTY_NINE',
  },
  {
    label: i18next.t('50 - 65 years'),
    value: 'FIFTY_TO_SIXTY_FIVE',
  },
  {
    label: i18next.t('Over 65 years'),
    value: 'OVER_SIXTY_FIVE',
  },
];

const hours_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Morning'),
    value: 'morning',
  },
  {
    label: i18next.t('Afternoon'),
    value: 'afternoon',
  },
  {
    label: i18next.t('Evening'),
    value: 'evening',
  },
  {
    label: i18next.t('Night'),
    value: 'night',
  },
  {
    label: i18next.t('Flexible working'),
    value: 'flexible_working',
  },
];

const Residents: React.FC = () => {
  const { setSurveyID, currentSurveyID } = useContext(SurveyContext);
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('survey_id') || undefined;
  if (!currentSurveyID) {
    setSurveyID(surveyId);
  }
  const { data, mutate } = useAPI<Values>(
    `/api/survey/${currentSurveyID?.survey_id}/residents/get/`
  );

  function useOnSubmit(): (
    data: Values,
    formHelpers: Pick<
      FormikHelpers<Values>,
      'setSubmitting' | 'setErrors' | 'setStatus'
    >
  ) => void {
    const navigate = useNavigate();
    const submitForm = useSubmitFormToAPI<Values, null>(
      `/api/survey/${currentSurveyID?.survey_id}/residents/set/`
    );

    return useCallback(
      async (submitData, formHelpers) => {
        const [hasSucceeded] = await submitForm(submitData, formHelpers);
        if (hasSucceeded) {
          mutate();
          navigate(`/floor?survey_id=${currentSurveyID?.survey_id}`);
        }
      },
      [navigate, submitForm]
    );
  }
  const addPerson = useCallback(() => {
    apiRequest(
      `/api/survey/${currentSurveyID?.survey_id}/residents/resident/add_blank/`,
      {
        method: 'POST',
      }
    ).then(() => mutate());
  }, [currentSurveyID?.survey_id, mutate]);

  const deleteSource = useCallback(
    (blockId: string) => {
      apiRequest(
        `/api/survey/${currentSurveyID?.survey_id}/residents/resident/delete/${blockId}/`,
        {
          method: 'POST',
        }
      ).then(() => mutate());
    },
    [currentSurveyID?.survey_id, mutate]
  );

  const onSubmit = useOnSubmit();
  return (
    <div className="space-y-6">
      {data ? (
        <>
          <h1 className="text-2xl font-semibold text-gray-900">
            <div>9.</div>
            <div>{i18next.t('Residents')}</div>
          </h1>
          <div className="text-base font-normal p-0 m-0 pb-1">
            {i18next.t(
              'Add the number of residents and then fill in their details'
            )}
          </div>
          <Formik<Values>
            enableReinitialize
            initialValues={data}
            onSubmit={onSubmit}
          >
            {({ status, values, handleChange, setFieldValue, errors }) => (
              <Form className="space-y-8 ">
                <div>
                  <FieldArray name="residents">
                    {() => (
                      <div>
                        {values.residents.length > 0 &&
                          values.residents.map((resident, index) => (
                            <div
                              className="bg-gray-100 p-3 rounded my-6"
                              key={index}
                            >
                              <div className="flex justify-between align-top">
                                <div className="text-2xl font-semibold text-gray-900">
                                  {i18next.t('Resident')} {index + 1}
                                </div>
                                <button
                                  type="button"
                                  onClick={() =>
                                    deleteSource(
                                      resident.resident_data.resident_id
                                    )
                                  }
                                >
                                  x
                                </button>
                              </div>
                              <div className="text-lg font-bold p-0 m-0">
                                {i18next.t('Age')}
                              </div>
                              <div className="text-base font-normal p-0 m-0 pb-1">
                                {i18next.t('Please select one')}
                              </div>
                              <Radio
                                name={`residents.${index}.resident_data.age`}
                                options={age_options}
                                onChange={(value) =>
                                  setFieldValue(
                                    `residents.${index}.resident_data.age`,
                                    value
                                  )
                                }
                                errors={getIn(errors, `resident[${index}].age`)}
                                defaultChecked={
                                  values &&
                                  values.residents[index].resident_data.age
                                }
                              />
                              <div className="text-lg font-bold p-0 m-0 pt-3">
                                {i18next.t('Weekday hours at home')}
                              </div>
                              <div className="text-base font-normal p-0 m-0 pb-1">
                                {i18next.t('Please select all that apply')}
                              </div>
                              <Checkbox
                                key={
                                  index +
                                  '_weekday' +
                                  hours_options[0].label.toString()
                                }
                                option={{
                                  label: hours_options[0].label,
                                  value: `residents.${index}.weekday_hours_data.${hours_options[0].value}`,
                                }}
                                onChange={(field, value) => {
                                  setFieldValue(field, value);
                                }}
                                checked={
                                  values.residents[index].weekday_hours_data
                                    .morning
                                }
                              />
                              <Checkbox
                                key={
                                  index +
                                  '_weekday' +
                                  hours_options[1].label.toString()
                                }
                                option={{
                                  label: hours_options[1].label,
                                  value: `residents.${index}.weekday_hours_data.${hours_options[1].value}`,
                                }}
                                onChange={(field, value) => {
                                  setFieldValue(field, value);
                                }}
                                checked={
                                  values.residents[index].weekday_hours_data
                                    .afternoon
                                }
                              />
                              <Checkbox
                                key={
                                  index +
                                  '_weekday' +
                                  hours_options[2].label.toString()
                                }
                                option={{
                                  label: hours_options[2].label,
                                  value: `residents.${index}.weekday_hours_data.${hours_options[2].value}`,
                                }}
                                onChange={(field, value) => {
                                  setFieldValue(field, value);
                                }}
                                checked={
                                  values.residents[index].weekday_hours_data
                                    .evening
                                }
                              />
                              <Checkbox
                                key={
                                  index +
                                  '_weekday' +
                                  hours_options[3].label.toString()
                                }
                                option={{
                                  label: hours_options[3].label,
                                  value: `residents.${index}.weekday_hours_data.${hours_options[3].value}`,
                                }}
                                onChange={(field, value) => {
                                  setFieldValue(field, value);
                                }}
                                checked={
                                  values.residents[index].weekday_hours_data
                                    .night
                                }
                              />
                              <Checkbox
                                key={
                                  index +
                                  '_weekday' +
                                  hours_options[4].label.toString()
                                }
                                option={{
                                  label: hours_options[4].label,
                                  value: `residents.${index}.weekday_hours_data.${hours_options[4].value}`,
                                }}
                                onChange={(field, value) => {
                                  setFieldValue(field, value);
                                }}
                                checked={
                                  values.residents[index].weekday_hours_data
                                    .flexible_working
                                }
                              />
                              <div className="text-lg font-bold p-0 m-0 pt-3">
                                {i18next.t('Weekend hours at home')}
                              </div>
                              <div className="text-base font-normal p-0 m-0 pb-1">
                                {i18next.t('Please select all that apply')}
                              </div>
                              <Checkbox
                                key={
                                  index +
                                  '_' +
                                  hours_options[0].label.toString()
                                }
                                option={{
                                  label: hours_options[0].label,
                                  value: `residents.${index}.weekend_hours_data.${hours_options[0].value}`,
                                }}
                                onChange={(field, value) => {
                                  setFieldValue(field, value);
                                }}
                                checked={
                                  values.residents[index].weekend_hours_data
                                    .morning
                                }
                              />
                              <Checkbox
                                key={
                                  index +
                                  '_' +
                                  hours_options[1].label.toString()
                                }
                                option={{
                                  label: hours_options[1].label,
                                  value: `residents.${index}.weekend_hours_data.${hours_options[1].value}`,
                                }}
                                onChange={(field, value) => {
                                  setFieldValue(field, value);
                                }}
                                checked={
                                  values.residents[index].weekend_hours_data
                                    .afternoon
                                }
                              />
                              <Checkbox
                                key={
                                  index +
                                  '_' +
                                  hours_options[2].label.toString()
                                }
                                option={{
                                  label: hours_options[2].label,
                                  value: `residents.${index}.weekend_hours_data.${hours_options[2].value}`,
                                }}
                                onChange={(field, value) => {
                                  setFieldValue(field, value);
                                }}
                                checked={
                                  values.residents[index].weekend_hours_data
                                    .evening
                                }
                              />
                              <Checkbox
                                key={
                                  index +
                                  '_' +
                                  hours_options[3].label.toString()
                                }
                                option={{
                                  label: hours_options[3].label,
                                  value: `residents.${index}.weekend_hours_data.${hours_options[3].value}`,
                                }}
                                onChange={(field, value) => {
                                  setFieldValue(field, value);
                                }}
                                checked={
                                  values.residents[index].weekend_hours_data
                                    .night
                                }
                              />
                              <Checkbox
                                key={
                                  index +
                                  '_' +
                                  hours_options[4].label.toString()
                                }
                                option={{
                                  label: hours_options[4].label,
                                  value: `residents.${index}.weekend_hours_data.${hours_options[4].value}`,
                                }}
                                onChange={(field, value) => {
                                  setFieldValue(field, value);
                                }}
                                checked={
                                  values.residents[index].weekend_hours_data
                                    .flexible_working
                                }
                              />
                            </div>
                          ))}
                        <AddButton
                          className="mt-3 mb-3"
                          label={i18next.t('Add person')}
                          type="button"
                          onClick={addPerson}
                        />
                      </div>
                    )}
                  </FieldArray>

                  <TextArea
                    id="additional_notes"
                    label={i18next.t('Additional notes')}
                    instructions={i18next.t(
                      'For example scenario for hours home during weekday/weekends, etc.'
                    )}
                    name="additional_notes"
                    onChange={handleChange}
                    defaultValue={values && values.additional_notes}
                    placeholder={i18next.t('Add notes')}
                  />
                </div>
                <NavigationProgress
                  status={status}
                  path={`/potential-retrofit/?survey_id=${currentSurveyID?.survey_id}`}
                  progressPercentage={90}
                />
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Residents;
