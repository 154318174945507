import * as queryString from 'query-string';
import * as i18next from 'i18next';

export type URLParams = queryString.ParsedQuery<string | number | boolean>;

export type OverViewData = {
  address_line_1: string;
  address_line_2: string;
  county: string;
  postcode: string;
  town: string;
  section_colours: Record<string, string>;
  can_submit: boolean;
  is_submitted: boolean;
};

export type FormPagesDetail = { page: string; title: string };
export type FormPagesType = {
  APPLIANCES_AND_LIGHTING: FormPagesDetail;
  BUILDING_CONSTRUCTION: FormPagesDetail;
  BUILDING_LOCATION: FormPagesDetail;
  ENERGY_BILLS: FormPagesDetail;
  FLOOR_PLAN: FormPagesDetail;
  POTENTIAL_RETROFIT: FormPagesDetail;
  RESIDENTS: FormPagesDetail;
  SPACE_HEATING: FormPagesDetail;
  SURVEYOR_DETAILS: FormPagesDetail;
  VENTILATION: FormPagesDetail;
};
export const FormPages: FormPagesType = {
  APPLIANCES_AND_LIGHTING: {
    page: 'appliances-and-lighting',
    title: i18next.t('Appliances and lighting'),
  },
  BUILDING_CONSTRUCTION: {
    page: 'building-construction',
    title: i18next.t('Building construction'),
  },
  BUILDING_LOCATION: {
    page: 'building-location',
    title: i18next.t('Building location'),
  },
  ENERGY_BILLS: {
    page: 'energy-bills',
    title: i18next.t('Energy bills'),
  },
  FLOOR_PLAN: {
    page: 'floor',
    title: i18next.t('Floor plan'),
  },
  POTENTIAL_RETROFIT: {
    page: 'potential-retrofit',
    title: i18next.t('Potential retrofit'),
  },
  RESIDENTS: {
    page: 'residents',
    title: i18next.t('Residents'),
  },
  SPACE_HEATING: {
    page: 'space-heating',
    title: i18next.t('Heating'),
  },
  SURVEYOR_DETAILS: {
    page: 'surveyor-details',
    title: i18next.t('Details'),
  },
  VENTILATION: {
    page: 'ventilation',
    title: i18next.t('Ventilation'),
  },
};
