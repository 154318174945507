import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

export interface InputProps {
  label?: string;
  id?: string;
  name: string;
  defaultValue?: string;
  type?: string;
  placeholder?: string;
  errors?: ReadonlyArray<string>;

  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
  label,
  id,
  name,
  defaultValue = '',
  type = 'text',
  placeholder,
  errors = [],
  disabled,
}) => {
  const errorIds = errors.map((_, index) => `${name}-error-${index}`).join(' ');
  const hasErrors = errors.length > 0;

  const displayErrors = () => {
    if (!hasErrors) return;

    return errors.map((error, index) => (
      <p
        key={index}
        className="mt-2 text-sm text-red-600"
        id={`${name}-error-${index}`}
      >
        {error}
      </p>
    ));
  };

  const displayExclamationMarkIfError = () => {
    if (!hasErrors) return;

    return (
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <ExclamationCircleIcon
          className="h-5 w-5 text-red-500"
          aria-hidden="true"
        />
      </div>
    );
  };
  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
      )}
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type={type}
          name={name}
          id={id}
          className={classNames(
            'block w-full rounded-md border-0 py-1.5 pr-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:resize-none',
            {
              'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500':
                hasErrors,
            },
            {
              'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600':
                !hasErrors,
            }
          )}
          placeholder={placeholder}
          defaultValue={defaultValue}
          aria-invalid={hasErrors ? 'true' : 'false'}
          aria-describedby={errorIds}
          disabled={disabled}
        />

        {displayExclamationMarkIfError()}
      </div>

      {displayErrors()}
    </div>
  );
};

export default Input;
