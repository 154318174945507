import React from 'react';

import { useAPI } from '../../api/api';

interface CatsAndDogs {
  cats: number;
  dogs: number;
}

const ExampleAPILoad: React.FC = () => {
  const { data, isValidating, error } = useAPI<CatsAndDogs>('/api/example/');

  if (isValidating && !data) {
    return <p>Loading&hellip;</p>;
  }
  if (error) {
    return <p>Failed to load.</p>;
  }

  if (!data) {
    return null;
  }

  const { cats, dogs } = data;

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          This is data from the API
        </h3>
      </div>
      <div className="px-4 py-5 border-t border-gray-200 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Cats</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {cats}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Dogs</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {dogs}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default ExampleAPILoad;
