import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { LoggedInUserContext } from '../context-providers/logged-in-user';
import HeaderImage from './assets/HeaderImage';

const LoggedOutLayout: React.FC = () => {
  const user = useContext(LoggedInUserContext);

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="container flex flex-col px-6 py-6 mx-auto bg-white text-center">
      <div className="self-center pb-6">
        <HeaderImage />
      </div>
      <Outlet />
    </div>
  );
};

export default LoggedOutLayout;
