import React, { useCallback } from 'react';
import { useFormik } from 'formik';

import Radio from '../../framework-components/select/radio';
import { PrimaryButton } from '../../framework-components/button';
import { Option } from '../../framework-components/select/types';

const options: ReadonlyArray<Option> = [
  {
    label: 'Small',
    value: 'small',
    description: '4 GB RAM / 2 CPUS / 80 GB SSD Storage',
  },
  {
    label: 'Medium',
    value: 'medium',
    description: '8 GB RAM / 4 CPUS / 160 GB SSD Storage',
  },
  {
    label: 'Large',
    value: 'large',
    description: '16 GB RAM / 8 CPUS / 320 GB SSD Storage',
  },
];

const RadioExample: React.FC = () => {
  const formik = useFormik({
    initialValues: {
      plan: 'small',
    },
    onSubmit: (values) => {
      console.log(values); // eslint-disable-line no-console
    },
  });

  const onSubmitForm = formik.handleSubmit;

  const changeHandler = useCallback(
    (value: string) => {
      formik.setFieldValue('plan', value);
    },
    [formik]
  );

  return (
    <form onSubmit={onSubmitForm} className="space-y-2">
      <h2 className="text-lg font-semibold">Radio</h2>
      <Radio options={options} onChange={changeHandler} />
      <PrimaryButton label="Submit" type="submit" />
    </form>
  );
};

export default RadioExample;
