import React, { useCallback } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as i18next from 'i18next';

import { useSubmitFormToAPI } from '../../api/forms';
import WrappedField from '../forms/wrapped-field';
import ErrorText from '../error-text';
import { PrimaryButton } from '../../framework-components/button';

interface Values {
  email: string;
  password: string;
}

export function useOnSubmit(): (
  data: Values,
  formHelpers: Pick<
    FormikHelpers<Values>,
    'setSubmitting' | 'setErrors' | 'setStatus'
  >
) => void {
  const submitForm = useSubmitFormToAPI<Values, null>('/api/auth/login/');

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(data, formHelpers);
      if (hasSucceeded) {
        window.location.reload();
      }
    },
    [submitForm]
  );
}

const LoginForm: React.FC = () => {
  const onSubmit = useOnSubmit();

  return (
    <Formik<Values>
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status, errors }) => (
        <Form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-6">
            <WrappedField
              name="email"
              type="email"
              label={i18next.t('Email')}
              placeholder={i18next.t('Enter email')}
              errors={errors.email}
            />
            <WrappedField
              name="password"
              type="password"
              errors={errors.password}
              placeholder={i18next.t('Enter password')}
              label={i18next.t('Password')}
            />
          </div>
          <div className="py-6 space-y-6">
            <PrimaryButton
              type="submit"
              label={
                isSubmitting ? i18next.t('Submitting...') : i18next.t('Submit')
              }
            />
            {status && <ErrorText>{status}</ErrorText>}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
