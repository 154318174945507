import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Dialog } from '@headlessui/react';

import ExampleAPILoad from './example-api-load';
import ExampleToggle from '../example-toggle';
import Modal from '../modal';
import useModalState from '../../hooks/modals';
import ExampleAPITable from './example-api-table';

const Landing: React.FC = () => {
  const { isOpen, openModal, closeModal } = useModalState();
  const doneButtonRef = useRef(null);

  return (
    <div className="space-y-6">
      <h1 className="text-4xl font-extrabold text-gray-900">Welcome</h1>
      <p className="max-w-screen-md">
        This is the landing page for the project template. This demonstrates how
        you can setup a route as a single page app. Start building and have fun.
      </p>
      <p>
        <Link
          className="text-blue-600 underline hover:text-blue-900"
          to="/invalid-route"
        >
          Link to an invalid route.
        </Link>
      </p>
      <div>
        <Modal
          isOpen={isOpen}
          onClose={closeModal}
          initialFocus={doneButtonRef}
        >
          <>
            <Dialog.Title>A Modal Title</Dialog.Title>
            <p className="mt-3">Some Modal Content</p>
            <div className="flex justify-end">
              <button
                className="px-5 py-3 mt-5 border rounded-lg hover:ring"
                ref={doneButtonRef}
                onClick={closeModal}
              >
                Done
              </button>
            </div>
          </>
        </Modal>
        <button
          className="px-5 py-3 border rounded-lg hover:ring"
          onClick={openModal}
        >
          Open Modal
        </button>
      </div>
      <ExampleAPILoad />
      <ExampleToggle />
      <ExampleAPITable />
    </div>
  );
};

export default Landing;
