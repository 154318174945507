import React, { useCallback } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

import { SortDirection } from './types';

interface Props {
  children: React.ReactElement | string | undefined;
  param: string;
  onSort: (ordering: string, direction: SortDirection) => void;
  direction?: SortDirection;
}

const Sort: React.FC<Props> = ({ onSort, children, param, direction }) => {
  const onClickAsc = useCallback(() => onSort(param, ''), [onSort, param]);
  const onClickDesc = useCallback(() => onSort(param, '-'), [onSort, param]);
  const onClick = direction === '-' ? onClickAsc : onClickDesc;
  const Icon = direction === '-' ? ChevronDownIcon : ChevronUpIcon;

  return (
    <button
      className="flex flex-row items-center font-bold hover:opacity-90"
      onClick={onClick}
    >
      {children}
      <Icon className="w-4 h-4 ml-1" />
    </button>
  );
};

export default Sort;
