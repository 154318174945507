import classNames from 'classnames';
import React from 'react';

import Row from './row';
import Sort from './sort';
import { Field, RowClickedEvent, SortDirection } from './types';

export interface TableProps<T> {
  fields: ReadonlyArray<Field<T>>;
  data?: ReadonlyArray<T>;
  className?: string;
  emptyMessage?: string;
  onRowClicked?: RowClickedEvent<T>;
  onSort?: (ordering: string, direction: SortDirection) => void;
  sortDirection?: SortDirection;
  getRowKey(item: T): string;
}

export default function Table<T>({
  fields,
  data,
  className,
  emptyMessage,
  onRowClicked,
  onSort,
  sortDirection,
  getRowKey,
}: TableProps<T>): JSX.Element {
  return (
    <table className={classNames('min-w-full border table-auto', className)}>
      <thead>
        <tr className="text-left border-b">
          {fields.map(({ header, name, sortable }) => (
            <th key={name} className="px-4 py-3">
              {sortable && onSort ? (
                <Sort param={name} onSort={onSort} direction={sortDirection}>
                  {header}
                </Sort>
              ) : (
                header
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.length ? (
          data.map((item: T) => (
            <Row
              key={getRowKey(item)}
              item={item}
              fields={fields}
              onRowClicked={onRowClicked}
            />
          ))
        ) : (
          <tr>
            <td className="px-4 py-3" colSpan={fields.length}>
              {emptyMessage || 'No items.'}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
