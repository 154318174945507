import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { HomeIcon } from '@heroicons/react/20/solid';

interface Crumb {
  label: string;
  onClick: () => void;
}

interface Props {
  crumbs: ReadonlyArray<Crumb>;
}

const BreadCrumbs: React.FC<Props> = ({ crumbs }) => {
  const navigate = useNavigate();
  const navigateHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol
        role="list"
        className="flex space-x-4 rounded-md bg-white px-6 shadow"
      >
        <li className="flex">
          <div className="flex items-center">
            <button
              role="link"
              onClick={navigateHome}
              className="text-gray-400 hover:text-gray-500"
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </button>
          </div>
        </li>
        {crumbs.map((crumb, idx) => (
          <li key={crumb.label} className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <button
                role="link"
                onClick={crumb.onClick}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={idx === crumbs.length - 1 ? 'page' : undefined}
              >
                {crumb.label}
              </button>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
