import React from 'react';
import { Link } from 'react-router-dom';
import * as i18next from 'i18next';

import ProgressBar from '../framework-components/progressbar/ProgressBar';
import { SecondaryButton } from '../framework-components/button';
import ErrorText from './error-text';
interface Props {
  path: string;
  progressPercentage: number;
  status: string | null;
}

const NavigationProgress: React.FC<Props> = ({
  path,
  progressPercentage,
  status,
}) => {
  return (
    <div>
      <div className="mb-6 pt-6 space-x-6 text-center">
        <Link to={path}>
          <SecondaryButton
            className="bg-white text-gray-900 hover:text-gray-900 hover:bg-gray-200"
            label={i18next.t('Back')}
          />
        </Link>
        <SecondaryButton
          className="bg-gray-900 text-white hover:bg-gray-700"
          type="submit"
          label={i18next.t('Next')}
        />
        {status && <ErrorText>{status}</ErrorText>}
      </div>
      <ProgressBar percentage={progressPercentage} />
      <div className="my-6">
        <p className="text-center text-gray-500">
          {i18next.t('Need more info?')}
          <Link
            target="_blank"
            className="text-blue-600 hover:text-blue-900"
            to="/guidance"
          >
            {i18next.t('See guidance')}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default NavigationProgress;
