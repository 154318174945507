import React from 'react';

import Navbar from '../framework-components/navbar';

const Navbars: React.FC = () => {
  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">Navbars</h2>
      <Navbar
        navigation={[
          { name: 'Services', href: '#', current: true },
          { name: 'Work', href: '#', openInNewTab: true },
          { name: 'About', href: '#' },
          { name: 'Team', href: '#' },
        ]}
        profileActions={[
          { label: 'Profile', onClick: () => alert('Profile') },
          { label: 'Settings', href: '#', openInNewTab: true },
          { label: 'Logout', onClick: () => alert('Logging out!') },
        ]}
        appLogo={
          <a href="#">
            <img
              src="https://dabapps-website-next-production.s3.amazonaws.com/images/Logo.width-1440.jpg"
              alt="DabApps Logo"
              className="h-5 w-auto"
            />
          </a>
        }
      />
    </div>
  );
};

export default Navbars;
