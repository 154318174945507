import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import ForgotPassword from './components/auth/forgot-password';
import ForgotPasswordEmailSent from './components/auth/forgot-password-email-sent';
import Login from './components/auth/login';
import Registration from './components/auth/registration';
import RegistrationEmailSent from './components/auth/registration-email-sent';
import ResetPassword from './components/auth/reset-password';
import NotFound from './components/not-found';
import Landing from './components/landing/landing';
import CreateViewChoice from './components/pages/create-view-choice';
import Splash from './components/splash/splash';
import LoggedInLayout from './components/logged-in-layout';
import LoggedOutLayout from './components/logged-out-layout';
import StyleGuide from './components/styleguide';
import CreateNew from './components/pages/create-new';
import ViewExisting from './components/pages/view-existing';
import AssessmentOverview from './components/pages/assessment-overview';
import SubmissionSuccess from './components/pages/submission-success';
import AppliancesAndLighting from './components/pages/appliances-and-lighting';
import BuildingConstruction from './components/pages/building-construction';
import EnergyBills from './components/pages/energy-bills';
import Guidance from './components/pages/guidance';
import Floor from './components/pages/floor';
import PotentialRetrofit from './components/pages/potential-retrofit';
import Residents from './components/pages/residents';
import SpaceHeating from './components/pages/space-heating';
import SurveyorDetails from './components/pages/surveyor-details';
import Ventilation from './components/pages/ventilation';
import BuildingLocation from './components/pages/building-location';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<LoggedOutLayout />}>
        <Route path="splash" element={<Splash />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route
          path="forgot-password-email-sent"
          element={<ForgotPasswordEmailSent />}
        />
        <Route path="register" element={<Registration />} />
        <Route
          path="registration-email-sent"
          element={<RegistrationEmailSent />}
        />
        <Route path="reset-password/:uid/:token" element={<ResetPassword />} />
      </Route>
      <Route element={<LoggedInLayout />}>
        <Route path="/" element={<CreateViewChoice />} />
        <Route path="create-new" element={<CreateNew />} />
        <Route path="view-existing" element={<ViewExisting />} />
        <Route path="assessment-overview" element={<AssessmentOverview />} />
        <Route
          path="appliances-and-lighting"
          element={<AppliancesAndLighting />}
        />
        <Route
          path="building-construction"
          element={<BuildingConstruction />}
        />
        <Route path="building-location" element={<BuildingLocation />} />
        <Route path="energy-bills" element={<EnergyBills />} />
        <Route path="floor" element={<Floor />} />
        <Route path="potential-retrofit" element={<PotentialRetrofit />} />
        <Route path="residents" element={<Residents />} />
        <Route path="space-heating" element={<SpaceHeating />} />
        <Route path="surveyor-details" element={<SurveyorDetails />} />
        <Route path="ventilation" element={<Ventilation />} />
        <Route path="submission-success" element={<SubmissionSuccess />} />
        <Route path="guidance" element={<Guidance />} />
        <Route path="landing" element={<Landing />} />
        <Route path="*" element={<NotFound />} />
        <Route path="styleguide" element={<StyleGuide />} />
      </Route>
    </>
  )
);

export default router;
