import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

import { DropdownOption } from './types';
import DropdownInner from './dropdown-inner';
import { useAPI } from '../../api/api';
import ErrorText from '../../components/error-text';

interface Response {
  count: number;
  next: string | null;
  previous: string | null;
  results: ReadonlyArray<DropdownOption>;
}

interface Props {
  path: string;
  onSelect: (option: DropdownOption) => void;
  label: string;
}

const RemoteDropdown: React.FC<Props> = ({ path, onSelect, label }) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    null
  );
  const [localOptions, setLocalOptions] = useState<
    ReadonlyArray<DropdownOption>
  >([]);

  useEffect(() => {
    const handler = debounce((nextQuery) => {
      setDebouncedQuery(nextQuery);
    }, 300);
    handler(query);
    return () => {
      handler.cancel();
    };
  }, [query]);

  const {
    data: options,
    error,
    isValidating,
  } = useAPI<Response>(path, {
    search: debouncedQuery,
  });

  useEffect(() => {
    if (
      options &&
      JSON.stringify(options.results) !== JSON.stringify(localOptions)
    ) {
      setLocalOptions(options.results);
    }
  }, [options, localOptions]);

  const selectHandler = useCallback(
    (option: DropdownOption) => {
      setSelectedOption(option);
      onSelect(option);
    },
    [onSelect]
  );

  return (
    <div>
      <DropdownInner
        setQuery={setQuery}
        filteredOptions={localOptions}
        selectedOption={selectedOption}
        selectHandler={selectHandler}
        label={label}
        isLoading={isValidating}
      />
      {error && (
        <ErrorText className="mt-2 text-sm">
          There was an issue during search.
        </ErrorText>
      )}
    </div>
  );
};

export default RemoteDropdown;
