/* eslint-disable react/jsx-no-bind */
import React, {
  useState,
  useCallback,
  BaseSyntheticEvent,
  useEffect,
} from 'react';
import _ from 'lodash';
import * as i18next from 'i18next';

import { apiRequest } from '../api/api';
import Anchor from './anchor';
import CloseIcon from '../components/assets/CloseIcon';

interface PresignedPOSTParams {
  acl: string;
  key: string;
  policy: string;
  signature: string;
  AWSAccessKeyId: string;
}

interface PresignedURLData {
  url: string;
  fields: PresignedPOSTParams;
}

interface FileInfo {
  file_id: string;
  upload_to: PresignedURLData;
  file_uploaded_confirmation_url: string;
}

interface Props {
  fieldName: string;
  initialFileId?: string;
  section: string;
  submitButtonId?: string;
  clientId: string;
  instructions?: string;
}

const APPROX_TEN_MEGABYTES_IN_BYTES = 10000000;

function getExtensionFromFileName(filename: string): string {
  return filename.substr(filename.lastIndexOf('.') + 1, filename.length);
}

async function createNewFile(
  fileName: string,
  clientId: string,
  section: string
): Promise<FileInfo> {
  const fileExtension = getExtensionFromFileName(fileName);
  return await apiRequest<FileInfo>(
    `/api/survey/${clientId}/${section}/image/add/`,
    {
      method: 'POST',
      body: JSON.stringify({
        document_name: fileName.split('.')[0],
        file_extension: fileExtension,
      }),
    }
  );
}

async function uploadFileToS3(
  fileInfo: FileInfo,
  selectedFile: File
): Promise<void> {
  const uploadBodyData = new FormData();

  _.toPairs(fileInfo.upload_to.fields).map((fieldNameAndValue) => {
    const fieldName = fieldNameAndValue[0];
    const fieldValue = fieldNameAndValue[1];

    uploadBodyData.append(fieldName, fieldValue);
  });

  uploadBodyData.append('file', selectedFile);
  const uploadResponse = await fetch(fileInfo.upload_to.url, {
    method: 'POST',
    body: uploadBodyData,
  });
  if (!uploadResponse.ok) {
    const responseText = await uploadResponse.text();
    throw new Error(`Failed to upload file to S3: ${responseText}`);
  }
}

async function markFileUploadAsComplete(
  fileUploadConfirmationUrl: string
): Promise<void> {
  await apiRequest(fileUploadConfirmationUrl, { method: 'POST' });
}

const FileUpload: React.FunctionComponent<Props> = (props: Props) => {
  const [fileId, setFileId] = useState(props.initialFileId || '');
  const [fileUploadInProgress, setFileUploadInProgress] = useState(false);
  const [uploadError, setUploadError] = useState<null | string>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [imageData, setImageData] = useState<Array<{
    id: string;
    value: unknown;
  }> | null>(null);

  const deleteImage = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setIsDeleting(true);
    const buttonValue = e.currentTarget.value;
    apiRequest(`/api/survey/delete_image/${buttonValue}/`, {
      method: 'POST',
    }).then(() => setIsDeleting(false));
  }, []);

  useEffect(() => {
    if (!isDeleting) {
      fetch(`/api/survey/${props.clientId}/${props.section}/images/get/`)
        .then((response) => response.json())
        .then((data) => {
          const toArray = Object.entries(data).map(([k, v]) => ({
            id: k,
            value: v,
          }));
          return setImageData(toArray);
        });
    }
  }, [fileUploadInProgress, props.clientId, props.section, isDeleting]);

  const doUploadFile = useCallback(
    async (event: BaseSyntheticEvent) => {
      setFileUploadInProgress(true);
      const selectedFile = event.target.files[0];
      if (selectedFile.size > APPROX_TEN_MEGABYTES_IN_BYTES) {
        setUploadError(
          i18next.t(
            'This file is too large. The maximum file size accepted is 10MB.'
          )
        );
        setFileUploadInProgress(false);
        return;
      }

      if (selectedFile.size === 0) {
        setUploadError(i18next.t('This file is empty'));
        setFileUploadInProgress(false);
        return;
      }

      try {
        const fileInfo = await createNewFile(
          selectedFile.name,
          props.clientId,
          props.section
        );
        await uploadFileToS3(fileInfo, selectedFile);
        await markFileUploadAsComplete(fileInfo.file_uploaded_confirmation_url);

        setFileId(fileInfo.file_id);
        setFileUploadInProgress(false);
        if (props.submitButtonId) {
          const submitButton: HTMLButtonElement | null =
            document.getElementById(props.submitButtonId) as HTMLButtonElement;

          if (submitButton) {
            submitButton.disabled = false;
          }
        }
      } catch (e) {
        setUploadError(
          i18next.t('Sorry, there was a problem uploading the file.')
        );
      }

      setFileUploadInProgress(false);
    },
    [props.clientId, props.section, props.submitButtonId]
  );
  const resetInput = useCallback(
    (event: BaseSyntheticEvent) => {
      event.preventDefault();
      setFileId('');
      setUploadError(null);
      if (props.submitButtonId) {
        const submitButton: HTMLButtonElement | null = document.getElementById(
          props.submitButtonId
        ) as HTMLButtonElement;

        if (submitButton) {
          submitButton.disabled = true;
        }
      }
    },
    [setFileId, setUploadError, props.submitButtonId]
  );
  return (
    <div>
      <div className="text-lg font-bold p-0 m-0 pt-6">
        {i18next.t('Add photos')}
      </div>
      <div className="text-base font-normal p-0 m-0 pb-0 text-gray-400">
        {props.instructions || ''}
      </div>
      <input
        type="hidden"
        name={props.fieldName}
        value={fileId}
        onChange={(e) => e.preventDefault()}
      />

      {uploadError && (
        <p className="text-red-500">
          {uploadError}
          <Anchor onClick={resetInput}>
            {i18next.t('Click to try again')}
          </Anchor>
        </p>
      )}

      <div className="inline-flex mt-6 flex-wrap w-full mt-6">
        {!fileId && !uploadError && (
          <label>
            <input
              className="hidden"
              type="file"
              accept="image/*"
              onChange={doUploadFile}
            />
            <div className="text-base bg-blue-600 px-2 py-2 text-white">
              {fileUploadInProgress
                ? i18next.t('Uploading...')
                : i18next.t('Choose file')}
            </div>
          </label>
        )}
        {imageData
          ? imageData.map((image, idx) => (
              <div key={idx} className="ml-6 mb-4">
                <div className="relative">
                  <button
                    className="absolute -top-2 -right-5"
                    type="button"
                    value={image.id}
                    onClick={deleteImage}
                  >
                    <CloseIcon />
                  </button>
                  <img width="40px" height="40px" src={image.value as string} />
                </div>
              </div>
            ))
          : ''}
      </div>
    </div>
  );
};

export default FileUpload;
export { createNewFile, uploadFileToS3, markFileUploadAsComplete };
