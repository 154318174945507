import React from 'react';

import APITable from '../table/api-table';
import { Field } from '../table/types';

interface CatsAndDogs {
  id: string;
  cats: number;
  dogs: number;
}

const filterParams = ['cats', 'dogs'];

const fields: ReadonlyArray<Field<CatsAndDogs>> = [
  {
    name: 'cats',
    header: 'Cats',
    render: (item) => item.cats,
    sortable: true,
  },
  {
    name: 'dogs',
    header: 'Dogs',
    render: (item) => item.dogs,
    sortable: false,
  },
];

function getItemKey(item: CatsAndDogs): string {
  return item.id;
}

const ExampleAPITable: React.FC = () => (
  <div className="mt-10 space-y-6">
    <h3 className="text-lg font-medium leading-6 text-gray-900">
      Example API Table
    </h3>
    <APITable
      filterParams={filterParams}
      endpoint="/api/example-list/"
      fields={fields}
      getRowKey={getItemKey}
    />
  </div>
);

export default ExampleAPITable;
