import React from 'react';
import { Link } from 'react-router-dom';
import * as i18next from 'i18next';

import { PrimaryButton } from '../../framework-components/button';
import { EMAIL_ADDRESS } from '../../constants';
const CreateViewChoice: React.FC = () => {
  return (
    <div className="space-y-6 text-center">
      <h1 className="text-2xl font-semibold text-gray-900">
        {i18next.t('PRESS Assessment')}
      </h1>
      <p>
        <Link to="/create-new">
          <PrimaryButton label={i18next.t('Create new')} />
        </Link>
      </p>

      <p>
        <Link to="/view-existing">
          <PrimaryButton label={i18next.t('Edit/view existing')} />
        </Link>
      </p>

      <p className="text-center text-gray-500">
        {i18next.t('Need to get in touch?')}
        <a
          href={`mailto:${EMAIL_ADDRESS}`}
          className="text-blue-600 visited:text-blue-600 hover:text-blue-900"
        >
          {i18next.t('Contact us')}
        </a>
      </p>
    </div>
  );
};

export default CreateViewChoice;
