import classNames from 'classnames';
import React from 'react';

import Loading from '../loading';

interface Props {
  label: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
  color?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<Props> = ({
  label,
  onClick,
  isDisabled,
  isLoading,
  className,
  type,
}) => {
  const renderLoading = () => {
    if (!isLoading) return null;

    return <Loading />;
  };

  return (
    <button
      type={type || 'button'}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      className={classNames(
        'px-3.5 py-2.5 font-normal shadow-sm ring-1 ring-inset',
        className,
        { 'cursor-not-allowed': isDisabled }
      )}
    >
      <div className="flex items-center justify-center">
        {renderLoading()}
        {label}
      </div>
    </button>
  );
};

export const PrimaryButton: React.FC<Props> = (props) => {
  const primaryButtonProps: Props = {
    ...props,
    className: classNames(
      'w-full max-w-sm text-sm rounded-md bg-gray-900 px-2 py-1 font-normal text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 ring-gray-500',
      'disabled:bg-gray-100 disabled:text-gray-400 disabled:ring-gray-200',
      props.className
    ),
  };
  return <Button {...primaryButtonProps} />;
};

export const AddButton: React.FC<Props> = (props) => {
  const addButtonProps: Props = {
    ...props,
    className: classNames(
      'w-full max-w-sm text-sm rounded-md bg-white px-2 py-1 font-normal text-blue-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue ring-blue',
      'disabled:bg-gray-100 disabled:text-gray-400 disabled:ring-gray-200',
      props.className
    ),
  };
  return <Button {...addButtonProps} />;
};
export const SecondaryButton: React.FC<Props> = (props) => {
  const secondaryButtonProps: Props = {
    ...props,
    className: classNames(
      'rounded-3xl px-7 py-3 font-normal shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 ring-gray-900',
      'disabled:bg-gray-100 disabled:text-gray-400 disabled:ring-gray-200',
      props.className
    ),
  };
  return <Button {...secondaryButtonProps} />;
};

export const TertiaryButton: React.FC<Props> = (props) => {
  const tertiaryButtonProps: Props = {
    ...props,
    className: classNames(
      'rounded-3xl bg-white px-7 py-3 font-normal text-gray-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 ring-gray-900',
      'disabled:bg-gray-100 disabled:text-gray-300 disabled:ring-gray-300',
      props.className
    ),
  };
  return <Button {...tertiaryButtonProps} />;
};

export const ColorButton: React.FC<Props> = (props) => {
  const ColorButtonProps: Props = {
    ...props,
    className: classNames(
      `mt-3 mb-3 mr-6 rounded bg-${props.color || 'red'}-500 hover:bg-${
        props.color || 'red'
      }-600 text-white ring-gray-300`,
      `active:bg-${props.color || 'red'}-700 ring-${
        props.color || 'red'
      }-500 disabled:bg-${props.color || 'red'}-100 disabled:text-${
        props.color || 'red'
      }-400 text-base`,
      props.className
    ),
  };
  return <Button {...ColorButtonProps} />;
};

export const DangerButton: React.FC<Props> = (props) => {
  const dangerButtonProps: Props = {
    ...props,
    className: classNames(
      'bg-red-500 hover:bg-red-600 text-white ring-gray-300',
      'active:bg-red-700 ring-red-300 disabled:bg-red-100 disabled:text-red-400',
      props.className
    ),
  };
  return <Button {...dangerButtonProps} />;
};

export default Button;
