import React from 'react';
import { Link } from 'react-router-dom';
import * as i18next from 'i18next';

import { PrimaryButton } from '../../framework-components/button';
import ForgotPasswordForm from './forgot-password-form';

const ForgotPassword: React.FC = () => (
  <div className="space-y-6">
    <ForgotPasswordForm />

    <Link to="/login">
      <PrimaryButton type="submit" label={i18next.t('Back to login')} />
    </Link>
  </div>
);

export default ForgotPassword;
