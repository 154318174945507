import React from 'react';

import PaginationBar from './pagination-bar';
import Table, { TableProps } from './table';
import { ChangePageHandler, Paginated } from './types';

interface Props<T> extends Omit<TableProps<T>, 'data'> {
  data: Paginated<T> | undefined;
  onChangePage: ChangePageHandler;
  currentPage: number;
  pageSize: number;
  isLoading?: boolean;
}

function PaginatedTable<T>(props: Props<T>): JSX.Element {
  const {
    data,
    onChangePage,
    currentPage = 1,
    pageSize,
    isLoading,
    ...tableProps
  } = props;

  const totalPages = Math.ceil((data ? data.count : 1) / pageSize);

  return (
    <div className="space-y-4">
      <Table data={data?.results} {...tableProps} />
      <PaginationBar
        currentPage={currentPage}
        totalPages={totalPages}
        onChangePage={onChangePage}
        isLoading={isLoading}
        totalCount={data?.count || 0}
        pageSize={pageSize}
      />
    </div>
  );
}

export default PaginatedTable;
