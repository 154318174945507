/* eslint-disable react/jsx-no-bind */
import React, { useContext, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import * as i18next from 'i18next';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as JSZipUtils from 'jszip-utils';

import { SurveyContext } from '../../context-providers/survey-context';
import { PrimaryButton } from '../../framework-components/button';
import { apiRequest, useAPI } from '../../api/api';
import Tick from '../assets/Tick';

interface Data {
  export: string;
  images: Record<string, Record<string, string>>;
}

const SubmissionSuccess: React.FC = () => {
  const { setSurveyID, currentSurveyID } = useContext(SurveyContext);
  const [searchParams] = useSearchParams();
  const [generateState, setGenerateState] = useState(false);
  const surveyId = searchParams.get('survey_id') || undefined;
  if (!currentSurveyID) {
    setSurveyID(surveyId);
  }
  const zip = new JSZip();
  let count = 0;
  const { data, mutate } = useAPI<Data>(
    `/api/survey/${currentSurveyID?.survey_id}/export/get/`
  );
  const urls =
    data &&
    data.images &&
    Object.entries(data.images).map(([k, v]) => ({
      folder: k,
      location: Object.values(v).map((val) => val),
    }));

  const imageCount =
    urls &&
    urls
      .map((element) => {
        return element.location.length;
      })
      .reduce((a, b) => a + b, 0);

  const handleZip = (zipFilename: string) => {
    if (urls) {
      urls.forEach(function (url) {
        url.location.forEach(function (location) {
          const filename =
            location &&
            location.split('filename%3D%22')[1].split('%22&AWSAccessKeyId')[0];
          JSZipUtils.getBinaryContent(
            location,
            async function (
              err: unknown,
              urlData:
                | string
                | number[]
                | Uint8Array
                | ArrayBuffer
                | Blob
                | NodeJS.ReadableStream
                | Promise<
                    | string
                    | number[]
                    | Uint8Array
                    | ArrayBuffer
                    | Blob
                    | NodeJS.ReadableStream
                  >
            ) {
              if (err) {
                throw err;
              }
              zip.file(`${url.folder}/${filename}`, urlData, { binary: true });
              count++;
              if (count === imageCount) {
                const zipFile = await zip.generateAsync({ type: 'blob' });
                saveAs(zipFile, zipFilename);
              }
            }
          );
        });
      });
    }
  };
  const generateReport = () => {
    setGenerateState(true);
    apiRequest(`/api/survey/${currentSurveyID?.survey_id}/export/generate/`, {
      method: 'POST',
    })
      .then(() => mutate())
      .then(() => data && saveAs(data.export))
      .then(() => setGenerateState(false));
  };

  const downloadImages = () => {
    const fileName =
      data && data.export
        ? data.export
            .split('filename%3D%22')[1]
            .split('%22&AWSAccessKeyId')[0]
            .split('.xlsx')[0]
        : 'survey';
    handleZip(fileName + '_images.zip');
  };

  return (
    <div className="">
      <h1 className="text-4xl font-extrabold text-gray-900 text-center">
        {i18next.t('Your PRESS Assessment has been successfully submitted.')}
      </h1>
      <div className="flex py-1 justify-center">
        <Tick />
      </div>
      <p className="mt-6">
        <PrimaryButton
          onClick={() => generateReport()}
          label={
            !generateState
              ? i18next.t('Generate report')
              : i18next.t('Generating...')
          }
          isDisabled={!data}
        />
      </p>

      {data && data.images && (
        <p className="mt-6">
          <PrimaryButton
            onClick={() => downloadImages()}
            label={i18next.t('Download Images')}
            isDisabled={generateState}
          />
        </p>
      )}

      <p className="mt-12">
        <Link to="/create-new">
          <PrimaryButton label={i18next.t('Create new')} />
        </Link>
      </p>

      <p className="mt-6">
        <Link to="/view-existing">
          <PrimaryButton label={i18next.t('Edit/view existing')} />
        </Link>
      </p>
    </div>
  );
};

export default SubmissionSuccess;
