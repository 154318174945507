import React from 'react';
import { PaperClipIcon } from '@heroicons/react/24/solid';

import DescriptionList from '../../framework-components/descriptionList';

const DescriptionListExample = () => {
  const renderMockDownloadButton = () => {
    const blob = new Blob([''], { type: 'application/pdf' });

    return (
      <a
        href={URL.createObjectURL(blob)}
        className="font-medium text-indigo-600 hover:text-indigo-500"
        download
      >
        Download
      </a>
    );
  };

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">DescriptionList</h2>

      <DescriptionList
        items={[
          {
            label: 'Full name',
            value: 'Margot Foster',
          },
          {
            label: 'Application for',
            value: 'Backend Developer',
          },
          {
            label: 'Email address',
            value: 'margotfoster@example.com',
          },
          {
            label: 'Salary expectation',
            value: '$120,000',
          },
          {
            label: 'About',
            value:
              'Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.',
          },
          {
            label: 'Attachments',
            value: () => (
              <ul
                role="list"
                className="divide-y divide-gray-100 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">
                        resume_back_end_developer.pdf
                      </span>
                      <span className="flex-shrink-0 text-gray-400">2.4mb</span>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    {renderMockDownloadButton()}
                  </div>
                </li>
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">
                        coverletter_back_end_developer.pdf
                      </span>
                      <span className="flex-shrink-0 text-gray-400">4.5mb</span>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    {renderMockDownloadButton()}
                  </div>
                </li>
              </ul>
            ),
          },
        ]}
        title="Application Information"
        subtitle="Personal details and application."
      />
    </div>
  );
};

export default DescriptionListExample;
