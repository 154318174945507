export function omit<T>(
  object: Record<string, T>,
  keys: ReadonlyArray<string>
): Record<string, T> {
  return Object.keys(object).reduce<Record<string, T>>(
    (previousValue, currentValue) => {
      if (keys.includes(currentValue)) {
        return previousValue;
      }
      return {
        ...previousValue,
        [currentValue]: object[currentValue],
      };
    },
    {}
  );
}

export function pick<T>(
  object: Record<string, T>,
  keys: ReadonlyArray<string>
): Record<string, T> {
  return omit<T>(
    object,
    Object.keys(object).filter((key) => !keys.includes(key))
  );
}
