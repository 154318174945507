import React from 'react';

import Toggle from '../../framework-components/toggle';

const Toggles: React.FC = () => {
  const [toggleEnabled1, setToggleEnabled1] = React.useState(false);
  const [toggleEnabled2, setToggleEnabled2] = React.useState(false);
  const [toggleEnabled3, setToggleEnabled3] = React.useState(false);

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">Toggles</h2>

      <div className="my-4">
        <Toggle
          enabled={toggleEnabled1}
          onChange={setToggleEnabled1}
          className="mb-2"
        />
        <Toggle
          enabled={toggleEnabled2}
          onChange={setToggleEnabled2}
          label="Test Label"
          className="mb-2"
        />
        <Toggle
          enabled={toggleEnabled3}
          onChange={setToggleEnabled3}
          label="Test Label"
          labelDirection="left"
        />
      </div>
    </div>
  );
};

export default Toggles;
