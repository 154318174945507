import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import Loading from '../loading';

export interface FilterOption {
  label: string;
  value: string;
}

interface ColumnConfig<Row, Key extends keyof Row> {
  key: Key;
  header: string;
  renderCell?: (data: Row, key: Key) => React.ReactNode;
  enableSort?: boolean;
}

export type ColumnsConfig<Row> = {
  [Key in keyof Row]: ColumnConfig<Row, Key>;
};

export interface TableProps<Row> {
  columnsConfig: ColumnsConfig<Row>;
  enableSearch?: boolean;
  renderActions?: (row: Row) => React.JSX.Element;
  getData: (
    selectedFilters: Record<string, string>
  ) => Promise<ReadonlyArray<Row>>;
  getFilterOptions: (
    selectedFilters: Record<string, string>
  ) => Promise<FilterOption[]>;
}

const Table = <Row,>({
  columnsConfig,
  enableSearch,
  renderActions,
  getFilterOptions,
  getData,
}: TableProps<Row>): React.JSX.Element => {
  const [data, setData] = useState<ReadonlyArray<Row> | null>(null);
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = useState<ReadonlyArray<FilterOption> | null>(
    null
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ordering, _setOrdering] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, _setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasInitialized, setIsInitialLoad] = useState<boolean>(false);

  useEffect(() => {
    const filterPromise = getFilterOptions({}).then(setFilters);
    const dataPromise = getData({}).then(setData);

    Promise.all([filterPromise, dataPromise]).then(() => setIsLoading(false));

    if (!hasInitialized && !isLoading) {
      setIsInitialLoad(true);
    }
  }, [getData, getFilterOptions, setIsInitialLoad, hasInitialized, isLoading]);

  const combineFilters = () => {
    return {
      search: '',
      ordering: '',
      filters: '',
      page: '',
    };
  };

  // TODO: add debouncing
  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);

      const searchValue = e.target.value;

      setSearch(searchValue);

      const updatedFilters = {
        ...combineFilters(),
        search: searchValue,
      };

      const newFilters = getFilterOptions(updatedFilters).then(setFilters);
      const newData = getData(updatedFilters).then(setData);

      Promise.all([newFilters, newData]).then(() => setIsLoading(false));

      //TODO: remove console.log, this is because variables are not used
      console.log('search', search); // eslint-disable-line no-console
      console.log('filters', filters); // eslint-disable-line no-console
      console.log('ordering', ordering); // eslint-disable-line no-console
      console.log('page', page); // eslint-disable-line no-console
    },
    [getData, getFilterOptions, filters, ordering, page, search]
  );
  /**
   * TODO: anytime we change, search, filtering, ordering or the page it should call getFilterOptions and getData
   * setPage
   * setOrdering
   * setSearch
   *
   * {
   *   search: '',
   *   ordering
   *   filters
   * }
   */

  const renderLoading = () => {
    if (!isLoading) return null;

    return (
      <tr>
        <td
          colSpan={Object.keys(columnsConfig).length}
          className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
        >
          <div className="flex items-center justify-center">
            <Loading />
          </div>
        </td>
      </tr>
    );
  };

  const renderSearchBar = () => {
    if (!enableSearch) {
      return null;
    }

    return (
      <div className="flex flex-1 items-center justify-start px-5 py-5 md:justify-end lg:ml-6">
        <div className="w-full max-w-xs">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              id="search"
              name="search"
              className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Search"
              type="search"
              onChange={onSearchChange}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCustomActions = (row: Row) => {
    if (!renderActions) return null;

    return (
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
        {renderActions(row)}
      </td>
    );
  };

  const renderTableHeaderWithSort = (
    columnConfig: ColumnConfig<Row, keyof Row>
  ) => {
    if (!columnConfig.enableSort) {
      return columnConfig.header;
    }

    return (
      <a href="#" className="group inline-flex">
        {columnConfig.header}
        <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      </a>
    );
  };

  const renderTableHeaders = () => {
    const headers = Object.values(columnsConfig).map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (columnConfig: any, index: number) => (
        <th
          key={columnConfig.key}
          scope="col"
          className={classNames(
            {
              'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6':
                index === 0,
            },
            {
              'px-3 py-3.5 text-left text-sm font-semibold text-gray-900':
                index > 0,
            }
          )}
        >
          {renderTableHeaderWithSort(columnConfig)}
        </th>
      )
    );

    if (renderActions) {
      headers.push(
        <th
          key="actions"
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          <span className="sr-only">Actions</span>
        </th>
      );
    }

    return headers;
  };

  const renderTableData = () => {
    if (!hasInitialized) return renderLoading();
    if (!data) return null;

    return (
      <>
        <tr
          className={classNames(
            'absolute top-0 left-0 w-full h-full bg-white flex items-center justify-center z-10',
            { 'bg-opacity-50': isLoading },
            { 'bg-opacity-0 hidden': !isLoading }
          )}
        >
          <td
            colSpan={Object.keys(columnsConfig).length}
            className="whitespace-nowrap text-sm font-medium text-gray-900"
          >
            <div className="flex items-center justify-center">
              <Loading />
            </div>
          </td>
        </tr>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {data.map((row: any) => (
          <tr key={row.id}>
            {Object.values(columnsConfig).map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (columnConfig: any, index: number) => {
                if (columnConfig.renderCell) {
                  return columnConfig.renderCell(row, columnConfig.key);
                }

                return (
                  <td
                    key={columnConfig.key}
                    className={classNames({
                      'whitespace-nowrap px-3 py-4 text-sm text-gray-500':
                        index > 0,
                      'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6':
                        index === 0,
                    })}
                  >
                    {row[columnConfig.key]}
                  </td>
                );
              }
            )}
            {renderCustomActions(row)}
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 px-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg bg-gray-50">
              {renderSearchBar()}
              <table className="min-w-full">
                <thead>
                  <tr>{renderTableHeaders()}</tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white relative">
                  {renderTableData()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
