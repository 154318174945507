import React, { useCallback } from 'react';
import classNames from 'classnames';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';

import { DropdownOption } from './types';
import Spinner from '../../components/spinner';

interface Props {
  setQuery: (query: string) => void;
  filteredOptions: ReadonlyArray<DropdownOption>;
  selectedOption: DropdownOption | null;
  selectHandler: (option: DropdownOption) => void;
  label: string;
  isLoading: boolean;
  placeholder?: string;
}

const DropdownInner: React.FC<Props> = ({
  setQuery,
  filteredOptions,
  selectedOption,
  selectHandler,
  label,
  isLoading,
  placeholder,
}) => {
  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const getOptionClassname = useCallback(
    ({ active }: { active: boolean }) =>
      classNames(
        'relative cursor-default select-none py-2 pl-3 pr-9',
        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
      ),
    []
  );

  const getDisplayValue = useCallback(
    (option: DropdownOption) => option?.label,
    []
  );

  return (
    <Combobox as="div" value={selectedOption} onChange={selectHandler}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={changeHandler}
          aria-placeholder={placeholder}
          placeholder={placeholder}
          displayValue={getDisplayValue}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          {isLoading ? (
            <Spinner className="h-4 w-4" />
          ) : (
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          )}
        </Combobox.Button>

        {filteredOptions.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={option.value}
                value={option}
                className={getOptionClassname}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'block truncate',
                        selected && 'font-semibold'
                      )}
                    >
                      {option.label ? option.label : placeholder}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default DropdownInner;
