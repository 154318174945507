import React from 'react';

type DescriptionListValue = string | (() => React.JSX.Element);

interface DescriptionItem {
  label: string;
  value: DescriptionListValue;
}

export interface DescriptionListProps {
  items: ReadonlyArray<DescriptionItem>;
  title: string;
  subtitle?: string;
}

const DescriptionList: React.FC<DescriptionListProps> = ({
  items,
  title,
  subtitle,
}) => {
  const renderSubtitle = () => {
    if (!subtitle) return;

    return (
      <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
        {subtitle}
      </p>
    );
  };

  const renderItems = () => {
    return items.map(({ label, value }, index) => (
      <div
        key={index}
        className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
      >
        <dt className="text-sm font-medium leading-6 text-gray-900">{label}</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {typeof value === 'function' ? value() : value}
        </dd>
      </div>
    ));
  };

  return (
    <div>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          {title}
        </h3>
        {renderSubtitle()}
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">{renderItems()}</dl>
      </div>
    </div>
  );
};

export default DescriptionList;
