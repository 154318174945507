import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';

import Breadcrumbs from '../../framework-components/breadcrumbs/breadcrumbs';

const BreadcrumbsExample = () => {
  const navigate = useNavigate();

  const clickHandler = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate]
  );

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">Breadcrumbs</h2>

      <Breadcrumbs
        crumbs={[
          { label: 'Home', onClick: () => clickHandler('/') },
          { label: 'Styleguide', onClick: () => clickHandler('/styleguide') },
        ]}
      />
    </div>
  );
};

export default BreadcrumbsExample;
