import React, { useCallback } from 'react';

import Table, { FilterOption } from '../../framework-components/table';
import {
  DangerButton,
  SecondaryButton,
} from '../../framework-components/button';

type RowData = {
  name: string;
  title: string;
  email: string;
  role: string;
};

const Tables = () => {
  const secondaryButtonClickHandler = useCallback((row: RowData) => {
    alert(`Viewing ${row.name}`);
  }, []);

  const dangerButtonClickHandler = useCallback((row: RowData) => {
    alert(`Are you sure you want to delete ${row.name}`);
  }, []);

  const RenderActions = useCallback(
    (row: RowData) => (
      <div className="flex items-center">
        <SecondaryButton
          label="View"
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => secondaryButtonClickHandler(row)}
        />
        <DangerButton
          label="Delete"
          className="ml-2"
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => dangerButtonClickHandler(row)}
        />
      </div>
    ),
    [secondaryButtonClickHandler, dangerButtonClickHandler]
  );

  const renderCell = (data: RowData) => (
    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-5 w-5">
          <img
            className="rounded-full"
            src={`https://ui-avatars.com/api/?name=${data.name}`}
            alt=""
          />
        </div>
        <div className="ml-4">{data.email}</div>
      </div>
    </td>
  );

  const getDataPromise = useCallback(() => {
    return new Promise<RowData[]>((resolve) => {
      setTimeout(() => {
        resolve([
          {
            name: 'Lindsay Walton',
            title: 'Front-end Developer',
            email: 'lindsay.walton@example.com',
            role: 'Member',
          },
          {
            name: 'Courtney Henry',
            title: 'Designer',
            email: 'courtney.henry@example.com',
            role: 'Admin',
          },
          {
            name: 'Tom Cook',
            title: 'Backend Developer',
            email: 'tom.cook@example.com',
            role: 'Admin',
          },
          {
            name: 'Whitney Francis',
            title: 'Copywriter',
            email: 'whitney.francis@example.com',
            role: 'Admin',
          },
          {
            name: 'Leonard Krasner',
            title: 'Senior Designer',
            email: 'leonard.krasner@example.com',
            role: 'Owner',
          },
          {
            name: 'Floyd Miles',
            title: 'Principal Designer',
            email: 'floyd.miles@example.com',
            role: 'Member',
          },
        ]);
      }, 1000);
    });
  }, []);

  const getFilterOptions = useCallback((): Promise<FilterOption[]> => {
    return new Promise((resolve) => resolve([]));
  }, []);

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h3 className="text-2xl font-bold">Tables</h3>
      <Table<RowData>
        enableSearch
        columnsConfig={{
          name: {
            key: 'name',
            header: 'Name',
          },
          title: {
            key: 'title',
            header: 'Title',
            enableSort: true,
          },
          email: {
            key: 'email',
            header: 'Email',
            renderCell: renderCell,
          },
          role: {
            key: 'role',
            header: 'Role',
          },
        }}
        renderActions={RenderActions}
        getFilterOptions={getFilterOptions}
        getData={getDataPromise}
      />
    </div>
  );
};

export default Tables;
