import React, { useCallback } from 'react';

import { Option } from './types';
import ErrorText from '../../components/error-text';

interface Props {
  option: Option;
  checked: boolean;
  errors?: string;
  onChange: (value: string, checked: boolean) => void;
}

const Checkbox: React.FC<Props> = ({ option, checked, onChange, errors }) => {
  const handleChange = useCallback(() => {
    onChange(option.value, !checked);
  }, [option.value, onChange, checked]);

  return (
    <fieldset>
      <div className="space-y-5">
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              checked={checked}
              id={option.value}
              aria-describedby={`${option.value}-description`}
              name={option.value}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              onChange={handleChange}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor={option.value} className="font-medium text-gray-900">
              {option.label}
              {errors && <ErrorText>{errors}</ErrorText>}
            </label>
            {option.description && (
              <p id={`${option.value}-description`} className="text-gray-500">
                {option.description}
              </p>
            )}
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default Checkbox;
