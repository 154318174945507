/* eslint-disable react/jsx-no-bind */
import React from 'react';

import TextArea from '../../framework-components/textarea';

const TextAreas = () => {
  return (
    <div className="mt-10 mb-10 space-y-6">
      <h2 className="text-2xl font-bold">Text Areas</h2>

      <div className="w-full max-w-xs">
        <div className="mb-4">
          <TextArea
            onChange={() => ''}
            label="Description"
            name="description"
            placeholder="Enter a description"
          />
        </div>

        <div className="mb-4">
          <TextArea
            onChange={() => ''}
            label="Disabled Description"
            name="description"
            placeholder="Enter a description"
            disabled
          />
        </div>

        <div className="mb-4">
          <TextArea
            onChange={() => ''}
            label="Auto Grow Description"
            name="description"
            placeholder="Enter a description"
            autoGrow
          />
        </div>
      </div>
    </div>
  );
};

export default TextAreas;
