import React, { useCallback } from 'react';

import Alert from '../../framework-components/alert/alert';

const Alerts = () => {
  const displayErrorMessage = useCallback(
    () => (
      <div className="flex items-center flex-wrap">
        <img
          src="https://picsum.photos/id/237/200/300"
          alt="Random"
          className="h-20 w-20 object-cover rounded-full"
        />
        <div className="ml-4">
          <h3 className="text-lg">This is a custom error message</h3>
          <p>This is a custom error message</p>
        </div>
      </div>
    ),
    []
  );

  const showDoneAlert = useCallback(() => alert('Done!'), []);
  const showRetryingAlert = useCallback(() => alert('Retrying...'), []);

  return (
    <div className="mt-10 mb-10 space-y-6">
      <h1 className="text-2xl font-bold">Alerts</h1>
      <Alert
        variant="error"
        title="Error"
        message="This is an error message"
        dismissable
      />
      <Alert
        variant="warning"
        title="Warning"
        message="This is a warning message"
        dismissable
      />
      <Alert
        variant="info"
        title="Info"
        message="This is an info message"
        dismissable
      />
      <Alert
        variant="success"
        title="Success"
        message="This is a success message"
        dismissable
      />
      <Alert
        variant="success"
        title="Success"
        message="This is a success message with an action"
        dismissable
        actionLabel="Done"
        onClickAction={showDoneAlert}
      />

      <Alert
        variant="error"
        title="Error"
        message={displayErrorMessage}
        actionLabel="Retry"
        onClickAction={showRetryingAlert}
      />
    </div>
  );
};

export default Alerts;
