import React, { useCallback } from 'react';
import { useFormik } from 'formik';

import Checkbox from '../../framework-components/select/checkbox';
import { PrimaryButton } from '../../framework-components/button';

const CheckboxExample: React.FC = () => {
  const formik = useFormik({
    initialValues: {
      commentsEnabled: false,
    },
    onSubmit: (values) => {
      console.log(values); // eslint-disable-line no-console
    },
  });

  const onSubmitForm = formik.handleSubmit;

  const changeHandler = useCallback(
    (value: string, checked: boolean) => {
      formik.setFieldValue(value, checked);
    },
    [formik]
  );

  return (
    <form onSubmit={onSubmitForm} className="space-y-2">
      <h2 className="text-lg font-semibold">Checkbox</h2>
      <Checkbox
        option={{
          value: 'commentsEnabled',
          label: 'Enable comments',
          description: 'Allow comments on this post',
        }}
        checked={formik.values.commentsEnabled}
        onChange={changeHandler}
      />
      <PrimaryButton label="Submit" type="submit" />
    </form>
  );
};

export default CheckboxExample;
