/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as i18next from 'i18next';

import { SurveyContext } from '../../context-providers/survey-context';
import { apiRequest, useAPI } from '../../api/api';
import { ColorButton, PrimaryButton } from '../../framework-components/button';
import ErrorText from '../error-text';
import { FormPages, OverViewData } from '../../types';

const AssessmentOverview: React.FC = () => {
  const { setSurveyID, currentSurveyID } = useContext(SurveyContext);
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('survey_id') || undefined;
  if (!currentSurveyID) {
    setSurveyID(surveyId);
  }

  const { data, error } = useAPI(`/api/survey/${currentSurveyID?.survey_id}/`);
  const navigate = useNavigate();
  const isComplete = data ? (data as OverViewData).can_submit : false;

  const submitAssessment = () => {
    apiRequest(`/api/survey/${currentSurveyID?.survey_id}/submit/`, {
      method: 'POST',
    }).then(() =>
      navigate(`/submission-success?survey_id=${currentSurveyID?.survey_id}`)
    );
  };

  const hasBeenSubmitted = data ? (data as OverViewData).is_submitted : false;
  const sectionStatus = data ? (data as OverViewData).section_colours : null;
  const address = data ? (data as OverViewData) : null;
  const addressString = `${address?.address_line_1}, ${address?.town}, ${address?.county}, ${address?.postcode}`;
  return (
    <div>
      <h1 className="text-5xl font-semibold text-gray-900 pb-1">
        {i18next.t('PRESS Information')}
      </h1>
      <p className="text-gray-500 pb-3">{addressString}</p>
      <p className="text-gray-500 pb-3">
        {i18next.t('Click on a section to complete')}
      </p>
      <div>
        {sectionStatus && (
          <>
            <Link
              to={`/${FormPages.SURVEYOR_DETAILS.page}/?survey_id=${currentSurveyID?.survey_id}`}
            >
              <ColorButton
                color={sectionStatus['SURVEYOR_DETAILS']}
                label={`1. ${FormPages.SURVEYOR_DETAILS.title}`}
              />
            </Link>

            <Link
              to={`/${FormPages.BUILDING_LOCATION.page}/?survey_id=${currentSurveyID?.survey_id}`}
            >
              <ColorButton
                color={sectionStatus['BUILDING_LOCATION']}
                label={`2. ${FormPages.BUILDING_LOCATION.title}`}
              />
            </Link>

            <Link
              to={`/${FormPages.BUILDING_CONSTRUCTION.page}/?survey_id=${currentSurveyID?.survey_id}`}
            >
              <ColorButton
                color={sectionStatus['BUILDING_CONSTRUCTION']}
                label={`3. ${FormPages.BUILDING_CONSTRUCTION.title}`}
              />
            </Link>
            <Link
              to={`/${FormPages.VENTILATION.page}/?survey_id=${currentSurveyID?.survey_id}`}
            >
              <ColorButton
                color={sectionStatus['VENTILATION']}
                label={`4. ${FormPages.VENTILATION.title}`}
              />
            </Link>
            <Link
              to={`/${FormPages.SPACE_HEATING.page}/?survey_id=${currentSurveyID?.survey_id}`}
            >
              <ColorButton
                color={sectionStatus['SPACE_HEATING']}
                label={`5. ${FormPages.SPACE_HEATING.title}`}
              />
            </Link>
            <Link
              to={`/${FormPages.APPLIANCES_AND_LIGHTING.page}/?survey_id=${currentSurveyID?.survey_id}`}
            >
              <ColorButton
                color={sectionStatus['APPLIANCES_AND_LIGHTING']}
                label={`6. ${FormPages.APPLIANCES_AND_LIGHTING.title}`}
              />
            </Link>
            <Link
              to={`/${FormPages.ENERGY_BILLS.page}/?survey_id=${currentSurveyID?.survey_id}`}
            >
              <ColorButton
                color={sectionStatus['ENERGY_BILLS']}
                label={`7. ${FormPages.ENERGY_BILLS.title}`}
              />
            </Link>
            <Link
              to={`/${FormPages.POTENTIAL_RETROFIT.page}/?survey_id=${currentSurveyID?.survey_id}`}
            >
              <ColorButton
                color={sectionStatus['POTENTIAL_RETROFIT']}
                label={`8. ${FormPages.POTENTIAL_RETROFIT.title}`}
              />
            </Link>
            <Link
              to={`/${FormPages.RESIDENTS.page}/?survey_id=${currentSurveyID?.survey_id}`}
            >
              <ColorButton
                color={sectionStatus['RESIDENTS']}
                label={`9. ${FormPages.RESIDENTS.title}`}
              />
            </Link>
            <Link
              to={`/${FormPages.FLOOR_PLAN.page}/?survey_id=${currentSurveyID?.survey_id}`}
            >
              <ColorButton
                color={sectionStatus['FLOOR_PLAN']}
                label={`10. ${FormPages.FLOOR_PLAN.title}`}
              />
            </Link>
          </>
        )}
        <div>
          {hasBeenSubmitted && (
            <h4>
              {i18next.t(
                'You have already submitted this PRESS Assessment, click Resubmit to update.'
              )}
            </h4>
          )}
          {isComplete && !hasBeenSubmitted && (
            <h4>
              {i18next.t(
                'You have completed all sections and are ready to submit.'
              )}
            </h4>
          )}
          <PrimaryButton
            onClick={submitAssessment}
            className="mt-9 mb-12"
            type="button"
            label={
              hasBeenSubmitted ? i18next.t('Resubmit') : i18next.t('Submit')
            }
            isDisabled={Boolean(error) || !isComplete}
          />
          {status && <ErrorText>{status}</ErrorText>}
          <p className="text-center text-gray-500 pb-6">
            {i18next.t('Need more info?')}
            <Link
              target="_blank"
              className="text-blue-600 hover:text-blue-900"
              to="/guidance"
            >
              {i18next.t('See guidance')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AssessmentOverview;
