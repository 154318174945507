import React from 'react';
import * as i18next from 'i18next';

const RegistrationEmailSent: React.FC = () => (
  <div className="space-y-6 text-center">
    <h4 className="text-4xl font-bold text-left">
      {i18next.t('Registration email sent')}
    </h4>
    <div className="text-left whitespace-pre whitespace-pre-wrap text-base font-normal">
      {i18next.t('we-sent-a-link')}
    </div>
  </div>
);

export default RegistrationEmailSent;
