import React, { useCallback } from 'react';

import { APIError, useAPI } from '../../api/api';
import { useQueryParams } from '../../hooks/navigation';
import { pick } from '../../utils/objects';
import PaginatedTable from './paginated-table';
import { Field, Paginated, SortDirection } from './types';

interface Props<T> {
  filterParams: ReadonlyArray<string>;
  pageSize?: number;
  endpoint: string;
  fields: ReadonlyArray<Field<T>>;
  getRowKey(item: T): string;
}

function getEmptyMessage<T>(
  data: Paginated<T> | undefined,
  isLoading: boolean,
  error: APIError | Error | undefined
): string | undefined {
  if (error) {
    return 'Failed to load.';
  }
  if (!data && isLoading) {
    return 'Loading...';
  }
  if (!data && !isLoading) {
    return 'No data.';
  }
  if (data?.results.length === 0 && !isLoading) {
    return 'No results for your query.';
  }
}

export default function APITable<T>({
  filterParams,
  pageSize = 10,
  endpoint,
  fields,
  getRowKey,
}: Props<T>): JSX.Element {
  const { change: onChangeQuery, params } = useQueryParams();
  const { page, ordering } = params;
  const filters = pick(params, filterParams);
  const apiParams = {
    page_size: pageSize,
    ordering,
    page,
    ...filters,
  };
  const onChangePage = useCallback(
    (newPage: number) => {
      onChangeQuery({ ...params, page: newPage });
    },
    [params, onChangeQuery]
  );

  const onSort = useCallback(
    (newSort: string, newDirection: SortDirection) => {
      onChangeQuery({ ...params, ordering: `${newDirection}${newSort}` });
    },
    [params, onChangeQuery]
  );

  const { data, isValidating, error } = useAPI<Paginated<T>>(
    endpoint,
    apiParams
  );

  const direction =
    typeof ordering === 'string' && ordering[0] === '-' ? '-' : '';

  return (
    <PaginatedTable
      emptyMessage={getEmptyMessage(data, isValidating, error)}
      data={error ? undefined : data}
      fields={fields}
      onSort={onSort}
      sortDirection={direction}
      onChangePage={onChangePage}
      currentPage={params.page ? Number(params.page) : 1}
      pageSize={pageSize}
      getRowKey={getRowKey}
      isLoading={isValidating}
    />
  );
}
