import React, { useCallback } from 'react';

import LocalDropdown from '../../framework-components/dropdown/local-dropdown';
import RemoteDropdown from '../../framework-components/dropdown/remote-dropdown';
import { DropdownOption } from '../../framework-components/dropdown/types';

const Dropdowns: React.FC = () => {
  const options: DropdownOption[] = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2' },
    { value: '3', label: 'Option 3' },
    { value: '4', label: 'Option 4' },
    { value: '5', label: 'Option 5' },
  ];

  const onSelect = useCallback((item: DropdownOption) => {
    // eslint-disable-next-line no-console
    console.log(item);
  }, []);

  return (
    <div className="space-y-4 max-w-xs">
      <h2 className="text-2xl font-bold">Dropdowns</h2>
      <LocalDropdown
        options={options}
        onSelect={onSelect}
        label="Local dropdown"
      />
      <RemoteDropdown
        path="/api/example-dropdown-options/"
        onSelect={onSelect}
        label="Remote dropdown"
      />
    </div>
  );
};

export default Dropdowns;
