import classNames from 'classnames';
import React, { useCallback } from 'react';

import { Field, RowClickedEvent } from './types';

interface Props<T> {
  item: T;
  fields: ReadonlyArray<Field<T>>;
  onRowClicked?: RowClickedEvent<T>;
}

export default function Row<T>({
  item,
  fields,
  onRowClicked,
}: Props<T>): JSX.Element {
  const onClick = useCallback(
    () => (onRowClicked ? onRowClicked(item) : null),
    [item, onRowClicked]
  );

  return (
    <>
      <tr
        onClick={onClick}
        className={classNames(
          onRowClicked && 'hover:bg-slate-200/10 hover:cursor-pointer'
        )}
      >
        {fields.map(({ render, name, className }) => (
          <td
            key={name}
            className={classNames('px-4 py-3 break-all', className)}
          >
            {render(item)}
          </td>
        ))}
      </tr>
    </>
  );
}
