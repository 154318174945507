import React, { useCallback, useState } from 'react';

import { useSubmitDataToAPI } from '../../api/submit';

export function useOnClick(): {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  isSubmitting: boolean;
} {
  const [isSubmitting, setSubmitting] = useState(false);
  const submitDataToAPI = useSubmitDataToAPI('/api/auth/logout/');

  const onClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const { succeeded } = await submitDataToAPI(undefined, setSubmitting);
      if (succeeded) {
        window.location.reload();
      }
    },
    [setSubmitting, submitDataToAPI]
  );

  return {
    onClick,
    isSubmitting,
  };
}

const LogoutButton: React.FC = () => {
  const { onClick, isSubmitting } = useOnClick();

  return (
    <button
      className="align-middle pt-1"
      disabled={isSubmitting}
      onClick={onClick}
    >
      Log out
    </button>
  );
};

export default LogoutButton;
