import { default as i18next } from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: false,
  initImmediate: true,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: require('../../locale/en.json'),
    },
  },
  react: {
    defaultTransParent: 'span',
  },
  keySeparator: false,
  nsSeparator: false,
});

export default i18next;
