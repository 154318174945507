import React from 'react';
import { FieldAttributes, Field } from 'formik';

import FieldWrapper from './field-wrapper';

interface Props {
  label: string;
  errors?: Array<string> | string;
}

function WrappedField<T>(props: FieldAttributes<T> & Props): JSX.Element {
  const { label, className, name, errors, ...rest } = props;
  return (
    <FieldWrapper label={label} className={className} fieldName={name}>
      <Field
        name={name}
        {...rest}
        className={`mr-auto block max-w-md w-full rounded-md border-0 py-1.5 pr-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:resize-none
          ${
            errors && errors.length > 0
              ? ' border-red-500 ring-red-500'
              : ' border-gray-300 ring-gray-300'
          }
        `}
      />
    </FieldWrapper>
  );
}

export default WrappedField;
