import React, { useCallback, useContext } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as i18next from 'i18next';

import { useSubmitFormToAPI } from '../../api/forms';
import WrappedField from '../forms/wrapped-field';
import ErrorText from '../error-text';
import { PrimaryButton } from '../../framework-components/button';
import { SurveyContext } from '../../context-providers/survey-context';

interface Values {
  address_line_1: string;
  address_line_2: string;
  town: string;
  county: string;
  postcode: string;
}

const CreateNew: React.FC = () => {
  const { setSurveyID } = useContext(SurveyContext);
  function useOnSubmit(): (
    data: Values,
    formHelpers: Pick<
      FormikHelpers<Values>,
      'setSubmitting' | 'setErrors' | 'setStatus'
    >
  ) => void {
    const navigate = useNavigate();
    const submitForm = useSubmitFormToAPI<Values, null>('/api/survey/new/');

    return useCallback(
      async (data, formHelpers) => {
        const [hasSucceeded, response] = await submitForm(data, formHelpers);

        if (hasSucceeded && response) {
          setSurveyID(response['survey_id']);
          navigate(`/assessment-overview?survey_id=${response['survey_id']}`);
        }
      },
      [navigate, submitForm]
    );
  }

  const onSubmit = useOnSubmit();
  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-semibold text-gray-900">
        {i18next.t(
          'Enter data for Practical Retrofit Early-Stage Survey (PRESS)'
        )}
      </h1>
      <Formik<Values>
        initialValues={{
          address_line_1: '',
          address_line_2: '',
          town: '',
          county: '',
          postcode: '',
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, status, errors }) => (
          <Form className="space-y-8 ">
            <div className="space-y-6">
              <WrappedField
                name="address_line_1"
                label={i18next.t('Address 1')}
                errors={errors.address_line_1}
                placeholder={i18next.t('Enter Address line 1')}
              />
              <WrappedField
                name="address_line_2"
                label={i18next.t('Address 2')}
                errors={errors.address_line_2}
                placeholder={i18next.t('Enter Address line 2')}
              />
              <WrappedField
                name="town"
                label={i18next.t('Town')}
                errors={errors.town}
                placeholder={i18next.t('Enter town')}
              />
              <WrappedField
                name="county"
                label={i18next.t('County')}
                errors={errors.county}
                placeholder={i18next.t('Enter county')}
              />
              <WrappedField
                name="postcode"
                label={i18next.t('Postcode')}
                errors={errors.postcode}
                placeholder={i18next.t('Enter postcode')}
              />
            </div>

            <div className="py-6 space-y-6">
              <PrimaryButton
                type="submit"
                label={
                  isSubmitting
                    ? i18next.t('Submitting...')
                    : i18next.t('Submit')
                }
              />
              {status && <ErrorText>{status}</ErrorText>}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateNew;
