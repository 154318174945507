import React from 'react';

const DragIcon: React.FC = () => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="move">
      <path
        id="Vector"
        d="M20.114 10.2453L17.4562 7.58749C17.1608 7.29218 16.7015 7.29218 16.4062 7.58749C16.1108 7.8828 16.1108 8.34218 16.4062 8.63749L18.0468 10.2781H11.2546V3.45311L12.8624 5.09374C12.9937 5.22499 13.1905 5.32343 13.3874 5.32343C13.5843 5.32343 13.7483 5.2578 13.9124 5.09374C14.2077 4.79843 14.2077 4.33905 13.9124 4.04374L11.2546 1.38593C11.0577 1.18905 10.7952 1.0578 10.4999 1.0578C10.2046 1.0578 9.94209 1.15624 9.74522 1.38593L7.0874 4.04374C6.79209 4.33905 6.79209 4.79843 7.0874 5.09374C7.38271 5.38905 7.84209 5.38905 8.1374 5.09374L9.77803 3.45311V10.2781H2.95303L4.59365 8.63749C4.88896 8.34218 4.88896 7.8828 4.59365 7.58749C4.29834 7.29218 3.83896 7.29218 3.54365 7.58749L0.88584 10.2453C0.459277 10.6719 0.459277 11.3281 0.88584 11.7547L3.54365 14.4125C3.6749 14.5437 3.87178 14.6422 4.06865 14.6422C4.26553 14.6422 4.42959 14.5766 4.59365 14.4125C4.88896 14.1172 4.88896 13.6578 4.59365 13.3625L2.95303 11.7219H9.77803V18.5469L8.1374 16.9062C7.84209 16.6109 7.38271 16.6109 7.0874 16.9062C6.79209 17.2015 6.79209 17.6609 7.0874 17.9562L9.74522 20.614C9.94209 20.8109 10.2046 20.9422 10.4999 20.9422C10.7952 20.9422 11.0577 20.8437 11.2546 20.614L13.9124 17.9562C14.2077 17.6609 14.2077 17.2015 13.9124 16.9062C13.6171 16.6109 13.1577 16.6109 12.8624 16.9062L11.2546 18.5469V11.7219H18.0468L16.4062 13.3625C16.1108 13.6578 16.1108 14.1172 16.4062 14.4125C16.5374 14.5437 16.7343 14.6422 16.9312 14.6422C17.128 14.6422 17.3249 14.5766 17.4562 14.4125L20.114 11.7547C20.5077 11.3281 20.5077 10.6719 20.114 10.2453Z"
        fill="#637381"
      />
    </g>
  </svg>
);
export default DragIcon;
