import React from 'react';

import Toggles from './toggles';
import BlockTabsExample from './blockTabsExample';
import Navbars from './navbars';
import ExampleButtons from './example-buttons';
import DescriptionList from './descriptionList';
import PaginationBar from './pagination-bar-example';
import Inputs from './inputs';
import TextAreas from './textarea';
import Tables from './tables';
import Dropdowns from './dropdowns';
import Alerts from './alerts';
import BreadCrumbs from './breadcrumbs';
import Selects from './selects';
import StatsExample from './statsExample';
import ProgressBars from './ProgressBars';

const StyleGuide: React.FC = () => {
  return (
    <div className="mx-auto max-w-[1000px] my-8 px-8">
      <h1 className="text-4xl mb-8 ">StyleGuide</h1>
      <Navbars />
      <ExampleButtons />
      <Toggles />
      <BlockTabsExample />
      <DescriptionList />
      <PaginationBar />
      <TextAreas />
      <Inputs />
      <Tables />
      <Dropdowns />
      <Selects />
      <Alerts />
      <BreadCrumbs />
      <StatsExample />
      <ProgressBars />
    </div>
  );
};

export default StyleGuide;
