import React, { createContext, useCallback, useState } from 'react';

export interface Props {
  children: React.ReactNode;
}

export interface SurveyContextType {
  setSurveyID: (survey_id: string | undefined) => void;
  currentSurveyID: { survey_id: string | undefined } | undefined;
}

export const SurveyContext = createContext<SurveyContextType>({
  setSurveyID: () => {
    throw new Error('SurveyContext invoked but no provider found.');
  },
  currentSurveyID: { survey_id: undefined },
});

export const SurveyContextProvider: React.FC<Props> = ({ children }) => {
  const [currentSurveyID, setCurrentSurveyID] = useState<{
    survey_id: string | undefined;
  }>();
  const switchSurveyID = useCallback(
    (survey_id: string | undefined) =>
      setCurrentSurveyID({ survey_id: survey_id }),
    []
  );
  return (
    <SurveyContext.Provider
      value={{ setSurveyID: switchSurveyID, currentSurveyID }}
    >
      {children}
    </SurveyContext.Provider>
  );
};
export default SurveyContext;
