import React, { useCallback } from 'react';

import { Option } from './types';
import Checkbox from './checkbox';

interface Props {
  options: ReadonlyArray<Option>;
  onChange: (values: string[]) => void;
  currentValues: string[];
}

const MultiChoice: React.FC<Props> = ({ options, onChange, currentValues }) => {
  const handleChange = useCallback(
    (value: string, checked: boolean) => {
      if (checked) {
        onChange([...currentValues, value]);
      } else {
        onChange(currentValues.filter((item) => item !== value));
      }
    },
    [onChange, currentValues]
  );

  return (
    <div className="space-y-3">
      {options.map((option) => (
        <Checkbox
          key={option.value}
          option={option}
          checked={currentValues.includes(option.value)}
          onChange={handleChange}
        />
      ))}
    </div>
  );
};

export default MultiChoice;
