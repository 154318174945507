import React, { useState } from 'react';
import { Switch } from '@headlessui/react';

interface Props {
  label?: string;
}

const ExampleToggle: React.FC<Props> = ({ label = 'Toggle me' }) => {
  const [enabled, setEnabled] = useState(false);

  return (
    <div className="mt-4">
      <label>
        {label}
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={`${
            enabled ? 'bg-blue-600' : 'bg-gray-200'
          } relative inline-flex items-center h-6 rounded-full w-11 ml-4`}
        >
          <span className="sr-only">Enable notifications</span>
          <span
            className={`${
              enabled ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full`}
          />
        </Switch>
      </label>
    </div>
  );
};

export default ExampleToggle;
