import React from 'react';
import * as i18next from 'i18next';
import { Link } from 'react-router-dom';

import RegistrationForm from './registration-form';
import { EMAIL_ADDRESS } from '../../constants';

const Registration: React.FC = () => (
  <div className="space-y-6">
    <RegistrationForm />
    <p className="text-center text-gray-500">
      {i18next.t('Already a member?')}
      <Link className="text-blue-600 hover:text-blue-900" to="/login">
        {i18next.t('Log on')}
      </Link>
    </p>
    <p>
      <a
        href={`mailto:${EMAIL_ADDRESS}`}
        className="text-blue-600 visited:text-blue-600 hover:text-blue-900"
      >
        {i18next.t('Contact us')}
      </a>
    </p>
  </div>
);

export default Registration;
