import React from 'react';
import * as i18next from 'i18next';

export const TOOLTIP_STYLE = {
  backgroundColor: '#3758F9',
  opacity: '1',
  color: 'white',
  padding: '6px',
  textTransform: 'none',
  maxWidth: '300px',
  zIndex: 100,
};

export const Section2part1: React.FC = () => {
  return (
    <div className="m-1">
      <h4 className="font-extrabold">
        {i18next.t('2.1. Front facade orientation')}
      </h4>
      <p>{i18next.t('Guidance 2.1')}</p>
    </div>
  );
};

export const Section2part2: React.FC = () => {
  return (
    <div className="m-1">
      <h4 className="font-extrabold">{i18next.t('2.2.  Urban context')}</h4>
      <p>{i18next.t('Guidance 2.2')}</p>
    </div>
  );
};

export const Section2part3: React.FC = () => {
  return (
    <div className="m-1">
      <h4 className="font-extrabold">{i18next.t('2.3. Exposure')}</h4>
      <p>{i18next.t('Guidance 2.3')}</p>
    </div>
  );
};

export const Section3part1: React.FC = () => {
  return (
    <div className="m-1">
      <h4 className="font-extrabold">
        {i18next.t('3.1. Year of construction')}
      </h4>
      <p>{i18next.t('Guidance 3.1')}</p>
    </div>
  );
};

export const Section3part2: React.FC = () => {
  return (
    <div className="m-1">
      <h4 className="font-extrabold">{i18next.t('3.2 Floor area')}</h4>
      <p>{i18next.t('Guidance 3.2')}</p>
    </div>
  );
};
export const Section3part3: React.FC = () => {
  return (
    <div className="m-1">
      <h4 className="font-extrabold">
        {i18next.t('3.3 Type of construction')}
      </h4>
      <p>{i18next.t('Guidance 3.3')}</p>
    </div>
  );
};
export const Section3part4: React.FC = () => {
  return (
    <div className="m-1">
      <h4 className="font-extrabold">{i18next.t('3.4 Dwelling type')}</h4>
      <p>{i18next.t('Guidance 3.4')}</p>
    </div>
  );
};
export const Section3part6: React.FC = () => {
  return (
    <div className="m-1">
      <h4 className="font-extrabold">
        {i18next.t('3.6 Location of adjoining properties')}
      </h4>
      <p>{i18next.t('Guidance 3.6')}</p>
    </div>
  );
};
export const Section4part2: React.FC = () => {
  return (
    <div className="m-1">
      <h4 className="font-extrabold">
        {i18next.t('4.2. Additional ventilation')}
      </h4>
      <p className="bg-white p-6">
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('Extractor fan')}
          </span>
          <img className="w-32 h-32" src="/static/images/extractor-fan.jpg" />
        </div>
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('Cooker hood')}
          </span>
          <img className="w-32 h-32" src="/static/images/cooker-hood.png" />
        </div>
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('Cavity vents')}
          </span>
          <img className="w-32 h-32" src="/static/images/cavity-vents.png" />
        </div>
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('Positive input ventilation')}
          </span>
          <img
            className="w-32 h-32"
            src="/static/images/positive-input-ventilation.png"
          />
        </div>
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('All house - MVHR')}
          </span>
          <img className="w-32 h-32" src="/static/images/all-house-mvhr.jpg" />
        </div>
      </p>
    </div>
  );
};
export const Section5part22: React.FC = () => {
  return (
    <div className="m-1">
      <h4 className="font-extrabold">{i18next.t('5.2.2. Heating type')}</h4>
      <p className="bg-white p-6">
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('Fireplace')}
          </span>
          <img className="w-32 h-32" src="/static/images/fireplace.jpg" />
        </div>
        <div>
          <span className="text-gray-900 text-sm">{i18next.t('Stove')}</span>
          <img className="w-32 h-32" src="/static/images/stove.jpg" />
        </div>
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('Electric heater')}
          </span>
          <img className="w-32 h-32" src="/static/images/electric-heater.jpg" />
        </div>
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('Oil filled radiators')}
          </span>
          <img
            className="w-32 h-32"
            src="/static/images/oil-filled-radiator.jpg"
          />
        </div>
      </p>
    </div>
  );
};
export const Section6part1: React.FC = () => {
  return (
    <div className="m-1">
      <h4 className="font-extrabold">{i18next.t('6.1. Lighting')}</h4>
      <p className="bg-white p-6">
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('LED bulbs')}
          </span>
          <img className="w-32 h-32" src="/static/images/led-bulb.png" />
          <img className="w-32 h-32" src="/static/images/led-bulb2.png" />
        </div>
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('Compact fluorescent bulbs')}
          </span>
          <img
            className="w-32 h-32"
            src="/static/images/compact-flurescent.png"
          />
        </div>
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('Fluorescent bulbs')}
          </span>
          <img className="w-32 h-32" src="/static/images/fluorescent.jpg" />
        </div>
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('Halogen bulbs')}
          </span>
          <img className="w-32 h-32" src="/static/images/halogen.jpg" />
        </div>
        <div>
          <span className="text-gray-900 text-sm">
            {i18next.t('Incandescent bulbs')}
          </span>
          <img className="w-32 h-32" src="/static/images/incandescent.jpg" />
        </div>
      </p>
    </div>
  );
};

const Guidance: React.FC = () => {
  return (
    <div className="space-y-6">
      <h1 className="text-4xl font-extrabold text-gray-900">
        {i18next.t('Guidance')}
      </h1>
      <h2 className="text-2xl font-extrabold text-gray-900">
        2. {i18next.t('Building location')}
      </h2>
      <Section2part1 />
      <Section2part2 />
      <Section2part3 />
      <h2 className="text-2xl font-extrabold text-gray-900">
        3. {i18next.t('Building construction')}
      </h2>
      <Section3part1 />
      <Section3part2 />
      <Section3part3 />
      <Section3part4 />
      <Section3part6 />
      <h2 className="text-2xl font-extrabold text-gray-900">
        4. {i18next.t('Ventilation')}
      </h2>
      <Section4part2 />
      <h2 className="text-2xl font-extrabold text-gray-900">
        5. {i18next.t('Space heating')}
      </h2>
      <Section5part22 />
      <h2 className="text-2xl font-extrabold text-gray-900">
        6. {i18next.t('Appliances and lighting')}
      </h2>
      <Section6part1 />
    </div>
  );
};

export default Guidance;
