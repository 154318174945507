import React from 'react';

const Logo: React.FC = () => (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="check-circle">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 82.8C55.7599 82.8 65.1201 78.9229 72.0215 72.0215C78.9228 65.1202 82.8 55.76 82.8 46C82.8 36.24 78.9228 26.8798 72.0215 19.9785C65.1201 13.0771 55.7599 9.20001 46 9.20001C36.24 9.20001 26.8798 13.0771 19.9784 19.9785C13.0771 26.8798 9.19995 36.24 9.19995 46C9.19995 55.76 13.0771 65.1202 19.9784 72.0215C26.8798 78.9229 36.24 82.8 46 82.8ZM63.0522 40.0522C63.8901 39.1846 64.3537 38.0227 64.3433 36.8166C64.3328 35.6105 63.849 34.4567 62.9961 33.6038C62.1432 32.751 60.9895 32.2672 59.7834 32.2567C58.5773 32.2462 57.4153 32.7099 56.5478 33.5478L41.4 48.6956L35.4522 42.7478C34.5846 41.9099 33.4226 41.4462 32.2165 41.4567C31.0104 41.4672 29.8567 41.951 29.0038 42.8038C28.1509 43.6567 27.6671 44.8105 27.6567 46.0166C27.6462 47.2227 28.1098 48.3846 28.9478 49.2522L38.1478 58.4522C39.0104 59.3146 40.1802 59.799 41.4 59.799C42.6197 59.799 43.7895 59.3146 44.6522 58.4522L63.0522 40.0522Z"
        fill="#22AD5C"
      />
    </g>
  </svg>
);
export default Logo;
