import React, { useState, useCallback } from 'react';

import { DropdownOption } from './types';
import DropdownInner from './dropdown-inner';

interface Props {
  options: ReadonlyArray<DropdownOption>;
  onSelect: (option: DropdownOption) => void;
  label: string;
  placeholder?: string;
}

const LocalDropdown: React.FC<Props> = ({
  options,
  onSelect,
  label,
  placeholder,
}) => {
  const [query, setQuery] = useState('');
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    null
  );

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  const selectHandler = useCallback(
    (option: DropdownOption) => {
      setSelectedOption(option);
      onSelect(option);
    },
    [onSelect]
  );

  return (
    <DropdownInner
      placeholder={placeholder}
      setQuery={setQuery}
      filteredOptions={filteredOptions}
      selectedOption={selectedOption}
      selectHandler={selectHandler}
      label={label}
      isLoading={false}
    />
  );
};

export default LocalDropdown;
