import React from 'react';
import * as i18next from 'i18next';

const ForgotPasswordEmailSent: React.FC = () => (
  <div className="space-y-6 text-center">
    <h4 className="text-4xl font-bold text-left">
      {i18next.t('Forgot password email sent')}
    </h4>
    <div className="text-l text-gray-500">{i18next.t('set-new-password')}</div>
  </div>
);

export default ForgotPasswordEmailSent;
