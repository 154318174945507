import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { Fragment } from 'react';

export type Navigation = ReadonlyArray<{
  name: string;
  href: string;
  openInNewTab?: boolean;
  current?: boolean;
}>;

export type ProfileActions = ReadonlyArray<{
  label: string;
  href?: string;
  openInNewTab?: boolean;
  onClick?: () => void;
}>;

export interface NavbarProps {
  navigation: Navigation;
  appLogo?: React.ReactNode;
  profileActions: ProfileActions;
  className?: string;
}

const renderNavbarItems = (navigation: Navigation) =>
  navigation.map((item, index) => (
    <a
      key={index}
      href={item.href}
      target={item.openInNewTab ? '_blank' : '_self'}
      className={classNames(
        {
          'inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900':
            item.current,
        },
        {
          'inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700':
            !item.current,
        }
      )}
    >
      {item.name}
    </a>
  ));

const renderProfileActionsDropdown = (profileActions: ProfileActions) =>
  profileActions.map((item, index) => (
    <Menu.Item key={index}>
      {({ active }) => {
        return (
          <a
            href={item.href}
            target={item.openInNewTab ? '_blank' : '_self'}
            onClick={item.onClick}
            className={classNames(
              { 'bg-gray-100': active },
              'block px-4 py-2 text-sm text-gray-700'
            )}
          >
            {item.label}
          </a>
        );
      }}
    </Menu.Item>
  ));

const renderMobileNavbarItems = (navigation: Navigation) =>
  navigation.map((item, index) => (
    <Disclosure.Button
      key={index}
      as="a"
      href={item.href}
      target={item.openInNewTab ? '_blank' : '_self'}
      className={classNames(
        {
          'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700':
            !item.current,
        },
        {
          'block border-l-4 border-indigo-500 py-2 pl-3 pr-4 text-base font-medium text-indigo-700 bg-indigo-50':
            item.current,
        }
      )}
    >
      {item.name}
    </Disclosure.Button>
  ));

const Navbar: React.FC<NavbarProps> = ({
  navigation,
  appLogo,
  profileActions,
  className,
}) => {
  return (
    <Disclosure as="nav" className={classNames('bg-white shadow', className)}>
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                {appLogo && (
                  <div className="flex flex-shrink-0 items-center">
                    {appLogo}
                  </div>
                )}
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {renderNavbarItems(navigation)}
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {renderProfileActionsDropdown(profileActions)}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-4 pt-2">
              {renderMobileNavbarItems(navigation)}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
