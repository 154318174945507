import * as queryString from 'query-string';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { omit } from '../utils/objects';
import { formatUrlWithParams, urlParseOptions } from '../utils/urls';
import { URLParams } from '../types';

export function useQueryParams(): {
  params: URLParams;
  change: (newParams: URLParams) => void;
  clear: (paramsToClear?: ReadonlyArray<string>) => void;
} {
  const navigate = useNavigate();
  const location = useLocation();

  const params = queryString.parse(location.search, urlParseOptions);

  const change = useCallback(
    (newParams: URLParams) => {
      navigate(formatUrlWithParams(location.pathname, newParams));
    },
    [navigate, location.pathname]
  );

  const clear = useCallback(
    (paramsToClear?: ReadonlyArray<string>) => {
      change(paramsToClear ? omit(params, paramsToClear) : {});
    },
    [params, change]
  );

  return {
    params,
    change,
    clear,
  };
}
