import React from 'react';

type Props = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

const Anchor: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <a
      className="underline cursor-pointer text-brand-teal hover:text-brand-teal-dark active:text-brand-teal-dark text-left"
      {...props}
    >
      {props.children}
    </a>
  );
};

export default Anchor;
