import React, { useCallback } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as i18next from 'i18next';

import { PrimaryButton } from '../../framework-components/button';
import { useSubmitFormToAPI } from '../../api/forms';
import WrappedField from '../forms/wrapped-field';
import ErrorText from '../error-text';

interface Values {
  email: string;
}

export function useOnSubmit(): (
  data: Values,
  formHelpers: Pick<
    FormikHelpers<Values>,
    'setSubmitting' | 'setErrors' | 'setStatus'
  >
) => void {
  const navigate = useNavigate();
  const submitForm = useSubmitFormToAPI<Values, null>(
    '/api/auth/password/reset/'
  );

  return useCallback(
    async (data, formHelpers) => {
      const [hasSucceeded] = await submitForm(data, formHelpers);
      if (hasSucceeded) {
        navigate('/forgot-password-email-sent');
      }
    },
    [navigate, submitForm]
  );
}

const ForgotPasswordForm: React.FC = () => {
  const onSubmit = useOnSubmit();

  return (
    <Formik<Values>
      initialValues={{
        email: '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status }) => (
        <Form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-6">
            <h3 className="block text-lg font-medium leading-6 text-gray-900">
              {i18next.t('Forgot Password')}
            </h3>
            <WrappedField name="email" type="email" label="Email" />
          </div>
          <div className="py-6 space-y-6">
            <PrimaryButton
              type="submit"
              label={
                isSubmitting ? i18next.t('Submitting...') : i18next.t('Submit')
              }
            />
            {status && <ErrorText>{status}</ErrorText>}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
