import React from 'react';
import { ErrorMessage } from 'formik';

import ErrorText from '../error-text';

interface Props extends React.HTMLProps<HTMLDivElement> {
  label: string;
  fieldName: string;
}

const FieldWrapper: React.FC<Props> = ({
  label,
  fieldName,
  children,
  ...rest
}) => {
  return (
    <div {...rest}>
      <label
        htmlFor={fieldName}
        className={
          'block mb-2 text-sm font-light leading-4 text-gray-900 text-left'
        }
      >
        {label}
      </label>
      {children}
      <ErrorMessage name={fieldName} component={ErrorText} />
    </div>
  );
};

export default FieldWrapper;
