import React, { useContext } from 'react';
import { Link, Navigate, Outlet } from 'react-router-dom';

import Logo from './assets/Logo';
import LogoutButton from './auth/logout-button';
import { LoggedInUserContext } from '../context-providers/logged-in-user';
import { EMAIL_ADDRESS } from '../constants';
const LoggedInLayout: React.FC = () => {
  const user = useContext(LoggedInUserContext);

  if (!user) {
    return <Navigate to="/splash" />;
  }

  return (
    <div>
      <nav className="z-50 flex w-full fixed bg-gray-100 p-4 mx-auto align-middle">
        <Link to="/">
          <Logo />
        </Link>
        <div className="ml-auto mr-6 align-middle pt-1">
          <a href={`mailto:${EMAIL_ADDRESS}`} target="_blank">
            <span className="pl-1 ">Contact</span>
          </a>
        </div>
        <div>
          <LogoutButton />
        </div>
      </nav>
      <div className="px-6 pt-20 mx-auto bg-white ">
        <Outlet />
      </div>
    </div>
  );
};

export default LoggedInLayout;
