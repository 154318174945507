import * as i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';

import { PrimaryButton } from '../../framework-components/button';

const Splash: React.FC = () => (
  <div className="space-y-6">
    <h4 className="text-4xl font-bold text-left">
      {i18next.t(
        'Welcome to the practical retrofit early-stage survey tool (PRESS).'
      )}
    </h4>
    <div className="text-left whitespace-pre whitespace-pre-wrap text-base font-normal">
      {i18next.t('welcome-text')}
    </div>
    <p className="text-center">
      <Link to="/register">
        <PrimaryButton label={i18next.t('Get started')} />
      </Link>
    </p>
  </div>
);

export default Splash;
