import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { I18nextProvider } from 'react-i18next';

import i18next from './locale';
import router from './router';
import {
  LoggedInUser,
  LoggedInUserContext,
} from './context-providers/logged-in-user';
import { SurveyContextProvider } from './context-providers/survey-context';

type Settings = Partial<{
  SENTRY_DSN: string;
}>;

const settingsElement = document.getElementById('settings');

const settings: Settings =
  settingsElement && settingsElement.textContent
    ? JSON.parse(settingsElement.textContent)
    : {};

Sentry.init({
  dsn: settings.SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const loggedInUserElement = document.getElementById('logged_in_user');

const user: LoggedInUser | null =
  loggedInUserElement && loggedInUserElement.textContent
    ? JSON.parse(loggedInUserElement.textContent)
    : {};

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <I18nextProvider i18n={i18next}>
    <LoggedInUserContext.Provider value={user}>
      <SurveyContextProvider>
        <RouterProvider router={router} />
      </SurveyContextProvider>
    </LoggedInUserContext.Provider>
  </I18nextProvider>
);
